<nb-card [nbSpinner]="loader">
  <nb-card-body class="activities-inner">

    <!--  -->
    <nb-card *ngFor="let d of data" [accent]="d.accent" style="margin-left: 5%; margin-right: 5%">
      <nb-card-header>
        <div class="row">
          <div class="col-auto">
            <nb-user [name]="d.user.fullName" [title]="d.user.jobTitle" [picture]="d.user.thumbnail">
            </nb-user>
          </div>
          <div class="col-auto">
            <button nbButton size="small" (click)="_openUserDetail(d)">
              <nb-icon icon="info-outline" pack="eva"></nb-icon>
            </button>
          </div>

          <div class="col-auto mr-auto">
            <button [title]="d.catName" [status]="d.accent" nbButton size="small">
              <nb-icon [icon]="d.icon" pack="eva"></nb-icon>
            </button>
          </div>

          <div class="col-auto">
            <p style="font-weight: bold;">{{ utilCtrl.timestamp.toLocalDate(d.log.createdOn) }}</p>
          </div>
        </div>
      </nb-card-header>

      <nb-card-body>

        <!-- description -->
        <p>
          {{ d.log.description }}
        </p>
        <!-- description -->

        <!-- changes -->
        <nb-accordion *ngIf="d.log.changes.length>0">
          <nb-accordion-item>
            <nb-accordion-item-header>
              Campos modificados
            </nb-accordion-item-header>
            <nb-accordion-item-body>

              <!-- changes details -->
              <nb-accordion multi>
                <nb-accordion-item *ngFor="let change of d.log.changes;index as i">
                  <nb-accordion-item-header>{{ change.fieldName }} </nb-accordion-item-header>
                  <nb-accordion-item-body>
                    <b>De: </b> {{ change.oldValue }}
                    <br />
                    <b>Para: </b> {{ change.newValue }}
                  </nb-accordion-item-body>
                </nb-accordion-item>
              </nb-accordion>
              <!-- changes details -->

            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
        <!-- changes -->

      </nb-card-body>

    </nb-card>
    <!--  -->

  </nb-card-body>
</nb-card>