<div id="{{className}}" class="body-section activities">
  <!-- section-header -->
  <div id="activities-body-section-header" class="body-section-header">
    <nb-icon class="header-icon" icon="activity-outline" pack="eva"></nb-icon>
    <span class="header-title">Atividades</span>

    <a class="go-to-logs-btn" title="Ir para Logs"
      href="{{'#/main/project-management/kanban/activities-browser?boardId=' + pmCardModal.board.id + '&cardId=' + pmCardModal.card.id + '&showLogs=true'}}"
      target="_blank" rel="noopener noreferrer">
      <nb-icon icon="clock-rotate-left" pack="fas"></nb-icon>
      <span>Ir para Logs</span>
    </a>
  </div>
  <!-- section-header -->

  <div *ngIf="_loader" class="activities-loader">
    <span>Carregando...</span>
  </div>

  <div #ActivitiesList id="activities-list" class="activities-list">
    <!-- activity -->
    <div *ngFor="let activityData of activitiesDataForDisplay; trackBy: _trackByFn" class="activity"
      id="{{activityData.ui_id}}"
      [ngClass]="{'send-actv': activityData.activity.uName == pmCardModal.authService.localUser.uName, 'recived-actv': activityData.activity.uName != pmCardModal.authService.localUser.uName }">
      <!-- activity inner -->
      <div class="activity-inner">
        <div *ngIf="pmCardModal.board.integration.tm.active && timeRecordMap[activityData.activity.id]"
          title="Somatório de horas apontadas nesta atividade" class="total-hours-badge">
          <nb-icon icon="clock-outline" pack="eva"></nb-icon>
          <span>{{timeRecordMap[activityData.activity.id]}}</span>
        </div>

        <!-- activity-header -->
        <div class="activity-header">
          <nb-user [name]="activityData.user.fullName" [title]="activityData.user.jobTitle"
            [picture]="activityData.user.thumbnail">
          </nb-user>

          <!-- button-grp -->
          <div class="ic-button-grp">
            <span *ngIf="activityData.activity.pinned">
              <button nbButton title="Fixado" status="primary" appearance="ghost" size="tiny">
                <nb-icon icon="pin" pack="eva"></nb-icon>
              </button>
            </span>

            <span *ngIf="pmCardModal.board.integration.tm.active">
              <button nbButton title="Apontar Horas Na Atividade" status="primary" appearance="ghost" size="tiny"
                (click)="pmCardModal._tmIntegrationActivity(activityData.activity)">
                <nb-icon icon="clock-outline" pack="eva"></nb-icon>
              </button>
            </span>

            <button nbButton title="Copiar Link" size="tiny" appearance="ghost"
              (click)="_copyActivityLink(activityData.activity)">
              <nb-icon icon="link-2-outline" pack="eva"></nb-icon>
            </button>
          </div>
          <!-- button-grp -->

          <span class="activity-createdOn">
            {{ pmCardModal.utilCtrl.timestamp.toLocalDate(activityData.activity.createdOn) }}
          </span>

          <button *ngIf="activityData.activity.type == 'C'" nbButton title="Opções" size="small" appearance="ghost"
            [disabled]="_showActivityOptActivityId == activityData.activity.id || _editNoteId == activityData.activity.id"
            (click)="_showActivityOpt(activityData.activity.id)">
            <nb-icon icon="more-vertical-outline" pack="eva"></nb-icon>
          </button>

          <!-- Activity opts dropdown -->
          <ul #ActivityOptERef class="opt-dropdown"
            *ngIf="_showActivityOptActivityId == activityData.activity.id && activityData.activity.type == 'C'">

            <li *ngIf="!activityData.activity.pinned">
              <button nbButton size="small" (click)="_pinActv(activityData.activity)">
                <nb-icon icon="pin-outline" pack="eva"></nb-icon>
                Fixar
              </button>
            </li>

            <li *ngIf="activityData.activity.pinned">
              <button status="danger" nbButton size="small" (click)="_unpinActv(activityData.activity)">
                <nb-icon icon="pin-outline" pack="eva"></nb-icon>
                Desfixar
              </button>
            </li>

            <li *ngIf="activityData.activity.uName == pmCardModal.authService.localUser.uName">
              <button nbButton size="small" (click)="_openEditNoteActv(activityData.activity)">
                <nb-icon icon="edit-outline" pack="eva"></nb-icon>
                Editar
              </button>
            </li>

            <li>
              <button nbButton status="danger" size="small" (click)="_deleteNoteActv(activityData.activity)">
                <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
                Apagar
              </button>
            </li>
          </ul>
          <!-- Activity opts dropdown -->
        </div>
        <!-- activity-header -->

        <!-- activity-body -->
        <div class="activity-body">

          <div *ngIf="_editNoteId != activityData.activity.id" id="activity-content-{{activityData.ui_id}}"
            class="inner-html-content magic-text-editor-content">
          </div>

          <div class="fields-changes" *ngIf="activityData.activity.changes.length > 0">
            <div class="field" *ngFor="let change of activityData.activity.changes; index as i">
              <span class="field-label">
                {{ change.fieldName }}
              </span>

              <span class="field-value">
                De: {{ change.oldValue }}, Para: {{ change.newValue }}
              </span>
            </div>
          </div>

          <!-- edit note -->
          <div *ngIf="_editNoteId == activityData.activity.id">

            <magic-text-editor #tempNoteEditorERef [usersToMention]="pmCardModal.magicTextEditorUsersToMention"
              (onChange)="_editNoteActv_noteHtmlChange($event)"
              (onUsersMentionedChange)="_editNoteActv__noteUsersMentionedChange($event)"
              (onReady)="_editNoteActv_editorOnReady($event)">
            </magic-text-editor>

            <div class="ic-button-grp btn-row">
              <button nbButton [disabled]="_reviewsLoader" appearance="ghost" title="Cancelar" status="danger"
                (click)="_editNoteActv_cancel(activityData)">
                <nb-icon icon="close-outline" pack="eva"></nb-icon>
                Cancelar
              </button>

              <button nbButton appearance="ghost" title="Publicar" status="primary"
                (click)="_editNoteActv_save(activityData.activity)">
                <nb-icon icon="paper-plane" pack="far"></nb-icon>
                Publicar
              </button>
            </div>

          </div>
          <!-- edit note -->

          <!-- activity-attachments -->
          <div class="activity-attachments">
            <div *ngFor="let attachmentId of activityData.activity.attachments">
              <div class="pm-attachment" *ngIf="attachmentsMap[attachmentId]">
                <div class="attachment-icon" *ngIf="attachmentsMap[attachmentId].icon">
                  <nb-icon icon="{{attachmentsMap[attachmentId].icon.icon}}"
                    pack="{{attachmentsMap[attachmentId].icon.pack}}"
                    [style.color]="attachmentsMap[attachmentId].icon.colorHex"></nb-icon>
                </div>

                <div class="attachment-name">
                  <span class="attachment-name">
                    <a [href]="attachmentsMap[attachmentId].attachment.url"
                      target="_blank">{{attachmentsMap[attachmentId].attachment.name}}</a>
                  </span>
                  <span class="attachment-subtitle">
                    ({{pmCardModal.utilCtrl.formatters.formatBytes(attachmentsMap[attachmentId].attachment.size)}})
                    {{pmCardModal.utilCtrl.timestamp.toLocalDate(attachmentsMap[attachmentId].attachment.createdOn)}}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- activity-attachments -->

        </div>
        <!-- activity-body -->

        <!-- activity-footer -->
        <div class="activity-footer">
          <div class="reviews-wrap">
            <div class="reviews-text">
              <span
                *ngIf="activityData.activity.likes.length == 0 && activityData.activity.dislikes.length == 0 && activityData.activity.uName != pmCardModal.authService.localUser.uName">
                Esta informação foi útil?
              </span>
              <span
                *ngIf="activityData.activity.likes.length == 0 && activityData.activity.dislikes.length == 0 && activityData.activity.uName == pmCardModal.authService.localUser.uName">
                Ainda não existem avaliações
              </span>

              <!-- one review -->
              <span *ngIf="activityData.activity.likes.length == 1 && activityData.activity.dislikes.length == 0">
                1 pessoa achou essa informação útil
              </span>
              <span *ngIf="activityData.activity.likes.length == 0 && activityData.activity.dislikes.length == 1">
                1 pessoa achou essa informação inútil
              </span>

              <!-- more than one review -->
              <span *ngIf="(activityData.activity.likes.length + activityData.activity.dislikes.length) > 1">
                {{activityData.activity.likes.length}} de {{(activityData.activity.likes.length +
                activityData.activity.dislikes.length)}}
                usuários acharam essa informação útil
              </span>
            </div>
            <div class="reviews-btns ic-button-grp"
              *ngIf="_editNoteId != activityData.activity.id && activityData.activity.uName != pmCardModal.authService.localUser.uName">
              <button nbButton [disabled]="_reviewsLoader" appearance="ghost" title="útil" status="primary" size="small"
                (click)="_toggleLikeActivity(activityData.activity)">
                <nb-icon *ngIf="!activityData.activity.likes.includes(pmCardModal.authService.localUser.uName)"
                  icon="thumbs-up" pack="far"></nb-icon>
                <nb-icon *ngIf="activityData.activity.likes.includes(pmCardModal.authService.localUser.uName)"
                  icon="thumbs-up" pack="fas"></nb-icon>
              </button>
              <button nbButton [disabled]="_reviewsLoader" appearance="ghost" title="inútil" status="danger"
                size="small" (click)="_toggleDislikeActivity(activityData.activity)">
                <nb-icon *ngIf="!activityData.activity.dislikes.includes(pmCardModal.authService.localUser.uName)"
                  icon="thumbs-down" pack="far"></nb-icon>
                <nb-icon *ngIf="activityData.activity.dislikes.includes(pmCardModal.authService.localUser.uName)"
                  icon="thumbs-down" pack="fas"></nb-icon>
              </button>
            </div>
          </div>

          <div class="views-wrap">
            <div class="views-text">
              <span>
                Visualizações {{activityData.activity.views.length}}
                <nb-icon *ngIf="activityData.activity.views.length == 0"
                  title="{{'Visualizações ' + activityData.activity.views.length}}" size="small"
                  icon="eye-off-2-outline" pack="eva"></nb-icon>
                <nb-icon *ngIf="activityData.activity.views.length > 0" icon="eye-outline"
                  title="{{'Visualizações ' + activityData.activity.views.length}}" size="small" pack="eva"></nb-icon>
              </span>
            </div>
          </div>
        </div>
        <!-- activity-footer -->
      </div>
      <!-- activity inner -->
    </div>
    <!-- activity -->
  </div>

  <!-- pagination -->
  <ul *ngIf="activitiesData.length > 0" class="pagination">
    <li class="pagination-item">
      <button [disabled]="actualPage == 1" (click)="pageDown()">
        <nb-icon icon="chevron-left-outline" pack="eva"></nb-icon>
      </button>
    </li>
    <li class="pagination-item-inner">
      <span>
        <input type="number" id="input_page" name="input_page" [(ngModel)]="input_page" [min]="1" [max]="lastPage"
          (keyup.enter)="update_input_page()">
        / {{lastPage}}
      </span>
    </li>
    <li class="pagination-item">
      <button [disabled]="actualPage == lastPage" (click)="pageUp()">
        <nb-icon icon="chevron-right-outline" pack="eva"></nb-icon>
      </button>
    </li>
  </ul>
  <!-- pagination -->
</div>