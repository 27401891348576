import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import firebase from "firebase/compat/app";
import * as platform from 'platform';
import { Observable, shareReplay, Subscriber } from "rxjs";
import { Subscription } from 'rxjs';

import { environment } from 'environments/environment';

import { AuthPair, DeviceToken, FireFunctionsService, FireMessagingService, FirestoreService, Log, Notification, Role, RoleAuth, RoleUser, User } from '../../@firebase';
import { Badger, UtilService } from './util';

export class AuthPairToTest {
  field: string; value: string
}
export class AuthToTest {
  object: string; authPairs: AuthPairToTest[]; timeOfTest?: Date;
}

@Injectable()
export class AuthService {

  public firebaseUser: firebase.User = null;
  public localUser: User = new User();
  public token: DeviceToken = new DeviceToken();
  public initialized = false;

  // subs
  private userSub: Subscription = null;
  private authStateSub: Subscription = null;
  private unreadNotifSub: any = null;
  private _internalNotifSub: Subscriber<Notification[]> = null;
  private _internalUserChangeSub: Subscriber<User> = null;
  private _internalUserParametersChangeSub: Subscriber<Object> = null;
  private chatSubs: any[] = [];


  private userChanges$ = new Observable<User>((subscriber) => {
    this._internalUserChangeSub = subscriber;
    if (this.localUser) {
      subscriber.next(this.localUser);
    }
  }).pipe(shareReplay(1));
  public getUserChanges(): Observable<User> {
    return this.userChanges$;
  }
  public subscribeToUserChanges(callback: (user: User) => void): Subscription {
    return this.getUserChanges().subscribe(callback);
  }

  private userParametersChanges$ = new Observable<object>((subscriber) => {
    this._internalUserParametersChangeSub = subscriber;
    if (this.localUser?.parameters) {
      subscriber.next(this.localUser.parameters);
    }
  }).pipe(shareReplay(1));
  public getUserParametersChanges(): Observable<object> {
    return this.userParametersChanges$;
  }
  public subscribeToParameterChanges(callback: (params: object) => void): Subscription {
    return this.getUserParametersChanges().subscribe(callback);
  }

  // user data
  public numOfUnreadNotif: number = 0;
  public notifBadger = new Badger();

  private oldChats: string[] = [];
  private unreadMsgPerChat = new Map<string, boolean>();


  //RBAC
  private rbac_initialized = false;
  private rbac_roleAuths: RoleAuth[] = [];
  public rbac_not_authorized_auths: AuthToTest[] = [];
  public rbac_authorized_auths: AuthToTest[] = [];
  //RBAC

  constructor(
    private http: HttpClient,
    public readonly afAuth: AngularFireAuth,
    private aff: FireFunctionsService,
    private db: FirestoreService,
    private fm: FireMessagingService,
    private router: Router,
    private utilCtrl: UtilService
  ) { }

  private log(message?: any, ...optionalParams: any[]) {
    console.log(`[AuthService] ${message}`, ...optionalParams)
  }
  private logError(message?: any, ...optionalParams: any[]) {
    console.error(`[AuthService] ${message}`, ...optionalParams)
  }
  private logTime(label?: string) {
    if (!environment.production)
      console.time(`[AuthService] ${label}`)
  }
  private logTimeEnd(label?: string) {
    if (!environment.production)
      console.timeEnd(`[AuthService] ${label}`)
  }

  private getUserDocStatus(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afAuth.currentUser
        .then(fbUser => {
          this.db.afs.collection(this.db.COLLECTIONS.sys.users).doc(fbUser.uid).ref.get({ source: 'server' })
            .then(userDoc => {
              resolve(userDoc.exists);
            })
            .catch((e) => {
              if (e.code == "unavailable")
                resolve(this.getUserDocStatus());
              else
                reject(e)
            });
        })
        .catch(e => reject(e))
    })
  }

  private _open_UserSub(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afAuth.currentUser
        .then(fbUser => {
          this.userSub = this.db.afs.collection(this.db.COLLECTIONS.sys.users).doc(fbUser.uid).valueChanges()
            .subscribe(
              (userData) => {
                if (userData != null) {
                  const newUserData = new User(userData);
                  this.log('User data updated');
                  if (JSON.stringify(newUserData.parameters) != JSON.stringify(this.localUser.parameters)) {
                    this.log('User parameters changed');
                    if (this._internalUserParametersChangeSub)
                      this._internalUserParametersChangeSub.next(this.localUser.parameters);
                  }
                  this.localUser = newUserData;
                  if (this._internalUserChangeSub)
                    this._internalUserChangeSub.next(this.localUser);
                  resolve();
                } else
                  resolve();
              },
              (error) => {
                this.logError(`002`, `Error at User subscriber, message: ${error.message} error: ${JSON.stringify(error)}`);
                reject({ code: `002`, log: 'Error at User subscriber', message: error.message, error: error });
              });
        })
        .catch(e => reject(e))
    })
  }
  getIpAddressApi() {
    return this.http.get<{ ip: string }>('https://api.ipify.org?format=json');
  }
  getLocationApi() {
    return this.http.get('https://ipinfo.io/json');
  }
  async syncDeviceToken(): Promise<void> {
    try {
      // Essential
      this.token.id = await this.fm.getToken();
      this.token.uName = await this.getUName().then((uName) => uName);
      this.token.deviceInfo = platform.description;
      this.token.loginTime = this.firebaseUser.metadata.lastSignInTime;
      this.token.lastSeen = this.utilCtrl.timestamp.now();
      await this.db.sys.deviceTokens.set(this.token.id, this.token);
      this.log('Device Token Essential Information Synced');

      // Additional
      this.token.location = await this.getLocationApi().toPromise().then((res) => res["city"] + ', ' + res["region"] + ', ' + res["country"]);
      await this.db.sys.deviceTokens.set(this.token.id, this.token);
      this.token.ipAddress = await this.getIpAddressApi().toPromise().then((res) => res.ip);
      await this.db.sys.deviceTokens.set(this.token.id, this.token);
      this.log('Device Token Additional Information Synced');
    } catch (e) {
      this.logError(`Sync Device Token Error`, e);
      throw e;
    }
  }
  private _open_unreadNotifSub(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.unreadNotifSub = this.db.afs.collection('Notifications').ref
        .where('uName', '==', this.localUser.uName)
        .where('readed', '==', false)
        .orderBy('createdOn', 'desc')
        .onSnapshot(querySnapshot => {

          // Atualiza o número de notificações não lidas
          this.numOfUnreadNotif = querySnapshot.size;
          this.notifBadger.value = this.numOfUnreadNotif;

          let tempNotifications: Notification[] = [];
          for (let doc of querySnapshot.docs)
            tempNotifications.push(new Notification(doc.data()));

          if (this._internalNotifSub)
            this._internalNotifSub.next(tempNotifications);
          resolve();
        },
          (error) => {
            this.logError(`002`, `Error at notification subscriber, message: ${error.message} error: ${JSON.stringify(error)}`);
            if (this._internalNotifSub)
              this._internalNotifSub.error(error);
            reject({ code: `002`, log: 'Error at notification subscriber', message: error.message, error: error });
          }
        );
    })
  }
  private open_AllSubs(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._open_UserSub()
        .then(() => {
          return this._open_unreadNotifSub()
            .then(() => {
              resolve();
            })
        })
        .catch(e => reject(e))
    })
  }

  public onNotification(): Observable<Notification[]> {
    return new Observable<Notification[]>((subscriber) => {
      // Keep track of the Documents Changes
      this._internalNotifSub = subscriber;
      // this._internalNotifSub.next(this.notifications);

      // Provide a way of canceling and disposing the interval resource
      return function unsubscribe() {
        this._internalNotifSub = null;
      };
    });
  }

  private _close_UserSub(): Promise<void> {
    return new Promise((resolve) => {
      if (this.userSub != null) {
        this.userSub.unsubscribe();
        this.userSub = null;
      }
      this.localUser = new User();
      resolve();
    })
  }
  private _close_unreadNotifSub(): Promise<void> {
    return new Promise((resolve) => {
      if (this.unreadNotifSub != null) {
        this.unreadNotifSub();
        this.unreadNotifSub = null;
      }
      resolve();
    })
  }

  private close_AllSubs(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._close_UserSub()
        .then(() => {
          return this._close_unreadNotifSub()
            .then(() => {
              resolve()
            })
        })
        .catch(e => reject(e))
    })
  }

  /**
   * Inicia o Provider
   * @return Retorna uma `Promise<void>`
   */
  public init(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.initialized) {

        this.authStateSub = this.afAuth.authState.subscribe(
          dataAuth => {
            this.firebaseUser = dataAuth;

            this.initialized = true;
            if (this.firebaseUser != null) {
              this.syncDeviceToken();
              resolve(this.open_AllSubs());
            } else
              resolve(this.close_AllSubs());

          },
          (error) => {
            this.initialized = false;
            this.logError(`001`, `Error at authState subscriber, message: ${error.message} error: ${JSON.stringify(error)}`);
            reject({ code: `001`, log: 'Error at authState subscriber', message: error.message, error: error });
          }
        );

      } else {
        this.hasUser()
          .then(hasUser => {

            if (this.localUser.id == '' && hasUser == true) {
              this.log(`Already initialized, waiting for subscribers to update`);
              setTimeout(() => {
                resolve(this.init());
              }, 500);
            } else {
              resolve();
              this.log(`Already initialized`);
            }

          })
          .catch(e => reject(e))
      }
    });
  }

  private failSafe(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.initialized) {
        this.hasUser()
          .then(hasUser => {
            if (hasUser) {
              if (this.localUser.id != '') {
                resolve();
              } else {
                this.getUserDocStatus()
                  .then(userDocExists => {
                    if (userDocExists) {
                      if (this.userSub != null && !this.userSub.closed) {
                        this.log(`waiting for subscribers to update`);
                        setTimeout(() => {
                          resolve(this.failSafe());
                        }, 500);
                      } else {
                        resolve(
                          this.close_AllSubs()
                            .then(() => {
                              resolve(
                                this.open_AllSubs()
                                  .then(() => resolve(this.failSafe()))
                              )
                            })
                        )
                      }
                    } else {
                      reject({ code: `004`, log: 'AuthService Error user Doc Not Exists', message: 'AuthService Error user Doc Exists' });
                    }
                  })
                  .catch((e) => {
                    reject(e)
                  });
              }
            } else {
              this.logError(`Error app does not have a logged user`);
              reject({ code: `003`, log: 'Error app does not have a logged user', message: 'Error app does not have a logged user' });
            }
          })
          .catch(e => reject(e));
      } else {
        this.init()
          .then(() => {
            resolve(this.failSafe());
          })
          .catch(e => reject(e));
      }
    })
  }

  public hasUser(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afAuth.currentUser
        .then(fbUser => {
          if (fbUser == null)
            resolve(false);
          else
            resolve(true);
        })
        .catch(e => reject(e));
    });
  }

  /**
 * Retorna o usuário logado e inicia o provider caso necessario
 * @return Retorna uma `Promise<User>`
 */
  public getUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      return this.failSafe()
        .then(() => {
          return this.db.sys.users.getDataById(this.localUser.id)
            .then(user => {
              this.localUser = user;
              resolve(Object.assign({}, this.localUser));
            })
        })
        .catch(e => reject(e));
    });
  }
  public getUserInitials(): string {
    const names = this.localUser.fullName.split(' ');
    return names.map(n => n.charAt(0)).splice(0, 2).join('').toUpperCase();
  }

  /**
   * Retorna o Id do usuário logado e inicia o provider caso necessario
   * @return Retorna uma `Promise<User>`
   */
  public getUid(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.failSafe()
        .then(() => {
          resolve(this.localUser.id.toString());
        })
        .catch(e => reject(e))
        ;
    });
  }

  /**
   * Retorna o uName do usuário logado e inicia o provider caso necessario
   * @return Retorna uma `Promise<User>`
   */
  public getUName(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.failSafe()
        .then(() => {
          resolve(this.localUser.uName.toString());
        })
        .catch(e => reject(e))
        ;
    });
  }

  public getFireAuthCurrentUser(): Promise<firebase.User> {
    return this.afAuth.currentUser;
  }

  /**
   * Atualiza o usuário logado
   * @param {Partial<User>} user
   * @return Retorna uma `Promise<void>`
   */
  public update(user: Partial<User>): Promise<void> {
    return new Promise((resolve, reject) => {
      this.failSafe()
        .then(() => {
          this.getFireAuthCurrentUser()
            .then(fbUser => {
              resolve(this.db.afs.collection(this.db.COLLECTIONS.sys.users).doc<User>(fbUser.uid).update(user));
            })
            .catch(e => reject(e));
        })
        .catch(e => reject(e));
    });
  }

  public set_parameter(id: string, value: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.failSafe()
        .then(() => {
          this.getFireAuthCurrentUser()
            .then(fbUser => {
              this.localUser.parameters[id] = value;
              resolve(
                this.db.afs.collection(this.db.COLLECTIONS.sys.users).doc<User>(fbUser.uid)
                  .update({ 'parameters': this.db.object_assign(this.localUser.parameters) })
              );
            })
            .catch(e => reject(e));
        })
        .catch(e => reject(e));
    });
  }
  public get_parameter(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.failSafe()
        .then(() => {
          resolve(this.localUser.parameters[id])
        })
        .catch(e => reject(e));
    });
  }

  // Auth Methods
  createTempApp(): firebase.app.App {
    return firebase.initializeApp(environment.firebaseOptions, 'tempApp_' + new Date().toISOString());
  }
  public createUserWithEmailAndPassword(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return new Promise((resolve) => {
      resolve(this.createTempApp().auth().createUserWithEmailAndPassword(email, password))
    })
  }
  public loginWithEmail(email: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, password)
        .then(() => {
          resolve()
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  public loginWithGoogle(): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.afAuth
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(() => {
          resolve()
        })
        .catch((e) => {
          reject(e);
        });
    })
  }
  public loginWtihFacebook(): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.afAuth
        .signInWithPopup(new firebase.auth.FacebookAuthProvider())
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  public sendPasswordResetEmail(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afAuth.sendPasswordResetEmail(email)
        .then(() => {
          resolve();
          this.log('Password update email sent', 'info')
        })
        .catch(e => reject(e))

    })
  }
  public setPassword(newPassword: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afAuth.currentUser
        .then(user => {
          user.updatePassword(newPassword)
            .then(() => {
              this.log('User Password updated', 'info')
              resolve();
            })
            .catch((e) => {
              this.logError('Error on Password updating', e, 'error')
              reject(e)
            });
        })
        .catch(e => reject(e))
    })
  }
  public setInitialPassword(log: Log, uid: string, newPassword: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.aff.callHttps('SYS_setInitialPassword', { log: log, uid: uid, initPassword: newPassword })
        .then((data) => resolve(data))
        .catch((e) => reject(e))
    });
  }
  public logout_redirect() {
    this.router.navigate(['auth', 'login'])
  }
  public async logout(): Promise<void> {
    try {
      // Reset state variables
      this.initialized = false;

      await this.close_AllSubs();
      this.localUser = new User();

      this.rbac_initialized = false;
      this.rbac_roleAuths = [];
      this.rbac_not_authorized_auths = [];
      this.rbac_authorized_auths = [];

      // Delete device token if it exists
      if (this.token && this.token.id) {
        await this.db.sys.deviceTokens.delete(this.token.id);
        await this.fm.deleteToken();
      }

      // Sign out from Firebase Authentication
      await this.afAuth.signOut();

      this.log('User Sign Out');
    } catch (e) {
      this.logError('Logout Error', e);
      throw e;
    }
  }
  public isUserValid(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.getUser()
        .then(user => {
          let validFrom = true;
          let validTo = true;
          let date = new Date();
          if (user.validFrom != ``)
            validFrom = date.getTime() > this.getFromDateObj(user.validFrom).getTime();
          if (user.validTo != ``)
            validTo = date.getTime() < this.getToDateObj(user.validTo).getTime();
          resolve(validFrom && validTo)
        })
        .catch(e => reject(e))
    })
  }
  public isOtherUserValid(user: User): boolean {
    let validFrom = true;
    let validTo = true;
    let date = new Date();
    if (user.validFrom != ``)
      validFrom = date.getTime() > this.getFromDateObj(user.validFrom).getTime();
    if (user.validTo != ``)
      validTo = date.getTime() < this.getToDateObj(user.validTo).getTime();
    return (validFrom && validTo);
  }

  // R B A C
  private getFromDateObj(dateStr_yyyy_mm_dd: string): Date {
    let dateObj = new Date(
      Number(dateStr_yyyy_mm_dd.split('-')[0]),
      Number(dateStr_yyyy_mm_dd.split('-')[1]) - 1,
      Number(dateStr_yyyy_mm_dd.split('-')[2]),
      0, 0, 0, 0
    );
    return dateObj;
  }
  private getToDateObj(dateStr_yyyy_mm_dd: string): Date {
    let dateObj = new Date(
      Number(dateStr_yyyy_mm_dd.split('-')[0]),
      Number(dateStr_yyyy_mm_dd.split('-')[1]) - 1,
      Number(dateStr_yyyy_mm_dd.split('-')[2]),
      23, 59, 59, 0
    );
    return dateObj;
  }


  // init
  private rbac_getRoleUsers(): Promise<RoleUser[]> {
    return new Promise((resolve, reject) => {
      let tempRoleUsers = [];
      this.db.afs.collection(this.db.COLLECTIONS.sys.accessControl.authorizationProvisioning.roleUsers)
        .ref
        .where('uName', '==', this.localUser.uName)
        .get({ source: 'server' })
        .then(q => {
          for (let d of q.docs) {
            tempRoleUsers.push(d.data() as RoleUser);
          }
          resolve(tempRoleUsers);
        })
        .catch(e => reject(e))
    });
  }
  private rbac_getRoleAuths(roleId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.db.afs.collection(this.db.COLLECTIONS.sys.accessControl.authorizationProvisioning.roleAuths)
        .ref
        .where('roleId', '==', roleId)
        .get({ source: 'server' })
        .then(q => {
          for (let d of q.docs) {
            this.rbac_roleAuths.push(d.data() as RoleAuth);
          }
          resolve();
        })
        .catch(e => reject(e))
    });
  }
  rbac_init(): Promise<void> {
    return new Promise((resolve, reject) => {

      this.rbac_not_authorized_auths = [];
      this.rbac_authorized_auths = [];
      this.rbac_roleAuths = [];

      let actualDate = new Date();

      this.rbac_getRoleUsers()
        .then(roleUsers => {

          let tempRoleAuthsPromises = [];

          let temp_rolesIds = [];
          let temp_compositeRolesIds = [];

          for (let ru of roleUsers) {
            let tempFromDateObj = this.getFromDateObj(ru.from);
            let tempToDateObj = this.getToDateObj(ru.to);
            let tempRuValid = tempFromDateObj.getTime() < actualDate.getTime() && tempToDateObj.getTime() > actualDate.getTime();
            if (tempRuValid) {
              if (ru.type == 'D')
                temp_rolesIds.push(ru.roleId);
              else if (ru.type == 'I')
                temp_compositeRolesIds.push(ru.roleId);
            }
          }

          let tempCompositeRolesPromises = [];

          temp_compositeRolesIds.forEach(rn => {
            tempCompositeRolesPromises.push(
              this.db.afs.collection(this.db.COLLECTIONS.sys.accessControl.authorizationProvisioning.roles)
                .doc(rn).ref
                .get({ source: 'server' })
            )
          })

          return Promise.all(tempCompositeRolesPromises)
            .then(compositeRoles => {

              compositeRoles.forEach(cr_doc => {
                let tempCR = cr_doc.data() as Role;
                tempCR.childRoles.forEach(rn => {
                  if (temp_rolesIds.indexOf(rn) == -1)
                    temp_rolesIds.push(rn)
                })
              })

              temp_rolesIds.forEach(rn => {
                tempRoleAuthsPromises.push(this.rbac_getRoleAuths(rn))
              })

              this.rbac_roleAuths = [];
              return Promise.all(tempRoleAuthsPromises)
                .then(() => {
                  this.rbac_initialized = true;
                  resolve();
                })
            })
        })
        .catch((e) => { reject(e) })
    })
  }
  // init


  private rbac_failSafe(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.rbac_initialized) {
        if (this.hasUser()) {
          if (this.localUser.id != '') {
            resolve(this.rbac_init());
          } else {
            this.failSafe()
              .then(() => { resolve(this.rbac_failSafe()) })
              .catch((e) => { reject(e) })
          }
        } else {
          this.logError(`Error app does not have a logged user`);
          reject({ code: `003`, log: 'Error app does not have a logged user', message: 'Error app does not have a logged user' });
        }
      } else {
        resolve();
      }
    })
  }


  private auth_test_values(value_to_test: string, user_values: string[]): boolean {
    let authorized = false;
    if (user_values.join('').indexOf('*') != -1) {
      for (let w = 0; user_values.length > w && !authorized; w++) {
        if (user_values[w].indexOf('*') != -1) {
          let temp_user_values = user_values[w].split('*')[0];
          let temp_value_to_test = value_to_test.slice(0, user_values[w].indexOf('*'));
          authorized = temp_user_values == temp_value_to_test;
        }
      }
    } else {
      authorized = user_values.indexOf(value_to_test) != -1;
    }
    return authorized;
  }
  private auth_test_ap(ap_to_tests: AuthPairToTest[], aps_user: AuthPair[]): boolean {
    let authorized = true;
    let tempAuthorized_AP = [];
    for (let i = 0; ap_to_tests.length > i && authorized; i++) {
      for (let x = 0; aps_user.length > x && authorized; x++) {
        if (ap_to_tests[i].field == aps_user[x].field) {
          // for (let y = 0; ap_to_tests[i].values.length > y && authorized; y++) {
          if (!this.auth_test_values(ap_to_tests[i].value, aps_user[x].values))
            authorized = false;
          else
            tempAuthorized_AP.push(ap_to_tests[i])
          // }
        }
      }
    }
    if (authorized && tempAuthorized_AP.length == ap_to_tests.length)
      return true;
    else
      return false;
  }


  public auth_check(auths_to_test: AuthToTest[]): Promise<AuthToTest[]> {
    return new Promise((resolve, reject) => {
      this.rbac_failSafe()
        .then(() => {

          this.logTime("auth_check_time")
          let authorized_auths: AuthToTest[] = [];
          let not_authorized_auths: AuthToTest[] = [];

          let SYS_ALL_USER = this.rbac_roleAuths.filter(ra => { return ra.objectId == '*' && ra.authPairs[0].field == '*' && ra.authPairs[0].values.indexOf('*') != -1 }).length > 0;

          if (SYS_ALL_USER) {
            let timeOfTest = new Date();
            auths_to_test.forEach(auth_to_test => {
              auth_to_test.timeOfTest = timeOfTest;
              this.rbac_authorized_auths.push(auth_to_test);
            })
            this.logTimeEnd("auth_check_time");
            if (authorized_auths.length > 0)
              this.log('authorized_auths: ' + authorized_auths.length)
            if (not_authorized_auths.length > 0)
              this.logError('not_authorized_auths: ' + not_authorized_auths.length)
            resolve([]);
          } else {
            auths_to_test.forEach(auth_to_test => {

              let tempObjectRas = this.rbac_roleAuths.filter(ra => { return ra.objectId == auth_to_test.object });

              let timeOfTest = new Date();
              auth_to_test.timeOfTest = timeOfTest;

              if (tempObjectRas.length == 0) {
                not_authorized_auths.push(auth_to_test)
              }
              else {

                let authorized = false;
                for (let ra of tempObjectRas) {

                  if (this.auth_test_ap(auth_to_test.authPairs, ra.authPairs)) {
                    authorized = true;
                  }
                  if (authorized) {
                    authorized_auths.push(auth_to_test);
                    break;
                  }
                }
                if (!authorized) {
                  not_authorized_auths.push(auth_to_test)
                }

              }
            });

            this.rbac_authorized_auths.push(...authorized_auths);
            this.rbac_not_authorized_auths.push(...not_authorized_auths);
            this.logTimeEnd("auth_check_time");
            if (authorized_auths.length > 0)
              this.log('authorized_auths: ' + authorized_auths.length)
            if (not_authorized_auths.length > 0)
              this.logError('not_authorized_auths: ' + not_authorized_auths.length)
            resolve(not_authorized_auths);
          }
        })
        .catch(e => reject(e))
        ;
    });

  }

  // R B A C
}
