<!-- pm-card -->
<div class="pm-card" [ngClass]="{'archived': card.status == 0}">
  <!-- pm-card-inner -->
  <div [id]="card.id" class="pm-card-inner" cdkDropList [cdkDropListData]="[card.id]"
    (cdkDropListDropped)="_onDropEmitter($event)">

    <!-- pm-card-header -->
    <div *ngIf="card" (click)="_click()">
      <div class="pm-card-header">
        <span title="{{card.title}}" class="pm-card-title">
          {{cardTitleToDisplay}}
        </span>

        <div class="subscriber-badge" *ngIf="card.subscribers.includes(authService.localUser.uName)"
          title="Notificações Ativas!">
          <nb-icon icon="bell-outline" status="warning" pack="eva"></nb-icon>
        </div>
      </div>
      <!-- pm-card-header -->

      <!-- ----------------------------------------------------- -->

      <!-- pm-card-labels -->
      <div class="pm-card-labels">
        <span class="pm-card-label" *ngFor="let label of _labels; trackBy: _trackByFn" [title]="label.name">
          <span class="pm-card-label-color" [style.background-color]="label.hex"
            [style.border-color]="label.hex"></span>
        </span>
      </div>
      <!-- pm-card-labels -->

      <!-- pm-card-badges -->
      <div class="pm-card-badges">
        <!-- priority-badge -->
        <div *ngIf="card.priority" class="pm-card-badge priority-badge" title="{{_priority.id}} - {{_priority.name}}"
          [style.color]="_priority.color" [style.background-color]="_priority.background_color">
          <span class="badge-text">
            <b>{{_priority.id}}</b>
          </span>
        </div>
        <!-- priority-badge -->

        <!-- due-date-badge -->
        <span class="pm-card-badge due-date-badge"
          *ngIf="_dueDateStatus.id !='recurrence-deadline-model' && _dueDateStr != ''" title="{{_dueDateStatus.name}}"
          [style.color]="_dueDateStatus.color" [style.background-color]="_dueDateStatus.background_color"
          [style.border-color]="_dueDateStatus.background_color">
          <span class="due-date">
            {{_dueDateStr}}
          </span>
        </span>
        <!-- due-date-badge -->

        <!-- template-badge -->
        <div *ngIf="card.originalCardId && card.template" class="pm-card-badge template-badge"
          title="Cartão criado a partir de um modelo">
          <nb-icon icon="copy-outline" status="success" pack="eva"></nb-icon>
        </div>

        <div *ngIf="!card.originalCardId && card.template" class="pm-card-badge template-badge"
          title="Este cartão é um modelo">
          <nb-icon icon="copy-outline" status="primary" pack="eva"></nb-icon>
        </div>
        <!-- template-badge -->

        <!-- repeat-badge -->
        <div *ngIf="card.originalCardId && card.recurrence" class="pm-card-badge repeat-badge"
          title="Cartão criado via recorrência">
          <nb-icon icon="repeat-outline" status="success" pack="eva"></nb-icon>
        </div>

        <div *ngIf="!card.originalCardId && card.recurrence" class="pm-card-badge repeat-badge"
          title="Este cartão possui recorrência ativa">
          <nb-icon icon="repeat-outline" status="primary" pack="eva"></nb-icon>
        </div>
        <!-- repeat-badge -->

        <!-- description -->
        <div *ngIf="board.compactCards && card.description != ''" class="pm-card-badge description"
          title="Este cartão tem uma descrição">
          <nb-icon icon="align-left" pack="fas"></nb-icon>
        </div>
        <!-- description -->

        <!-- related-cards -->
        <div *ngIf="card.relatedCardsIds.length > 0" class="pm-card-badge related-cards" title="Cartões Relacionados">
          <nb-icon icon="link-2-outline" pack="eva"></nb-icon>
          <span class="badge-text">{{card.relatedCardsIds.length}}</span>
        </div>
        <!-- related-cards -->

        <!-- to-do-list -->
        <div *ngIf="_toDoListStr != ''" class="pm-card-badge to-do-list"
          title="Somatório de horas apontadas neste Cartão">
          <nb-icon icon="checkmark-square-2-outline" pack="eva"></nb-icon>
          <span class="badge-text">{{_toDoListStr}}</span>
        </div>
        <!-- to-do-list -->

        <!-- sum-time-records -->
        <div *ngIf="showSumTimeRecords && sumTimeRecordsStr" class="pm-card-badge sum-time-records" title="Check Lists">
          <nb-icon icon="clock-outline" pack="eva"></nb-icon>
          <span class="badge-text">{{sumTimeRecordsStr}}</span>
        </div>
        <!-- sum-time-records -->
      </div>
      <!-- pm-card-badges -->

      <!-- ----------------------------------------------------- -->

      <!-- pm-card-body -->
      <div class="pm-card-body" *ngIf="!board.compactCards">

        <span class="description" *ngIf="card.description!=''">
          {{card.description}}
        </span>

        <!-- card-info -->
        <div *ngIf="board.showCardInfo" class="card-info">
          <div class="doc-fields">

            <!-- ------------------------ -->
            <div class="field">
              <div class="field-label">
                Criado Por
              </div>
              <div class="field-value">
                {{card.createdBy}}
              </div>
            </div>
            <!-- ------------------------ -->

            <!-- ------------------------ -->
            <div class="field">
              <div class="field-label">
                Criado Em
              </div>
              <div class="field-value">
                {{utilCtrl.timestamp.toLocalDate(card.createdOn)}}
              </div>
            </div>
            <!-- ------------------------ -->

            <!-- ------------------------ -->
            <div class="field">
              <div class="field-label">
                Atualizado Por
              </div>
              <div class="field-value">
                {{card.updatedBy}}
              </div>
            </div>
            <!-- ------------------------ -->

            <!-- ------------------------ -->
            <div class="field">
              <div class="field-label">
                Atualizado Em
              </div>
              <div class="field-value">
                {{utilCtrl.timestamp.toLocalDate(card.updatedOn)}}
              </div>
            </div>
            <!-- ------------------------ -->
          </div>
        </div>
        <!-- card-info -->
      </div>
      <!-- pm-card-body -->

      <!-- ----------------------------------------------------- -->

      <!-- pm-card-footer -->
      <div class="pm-card-footer">
        <div class="pm-card-assignees">
          <div class="pm-card-assignee" *ngFor="let assignee of _assignees; trackBy: _trackByFn"
            [title]="assignee.fullName">
            <nb-user [name]="assignee.fullName" [title]="assignee.jobTitle" [picture]="assignee.thumbnail" onlyPicture>
            </nb-user>
          </div>
        </div>

        <div class="pm-card-info">
          <span class="id">
            <span *ngIf="card.priority">
              [{{card.priority}}]{{card.id}}
            </span>
            <span *ngIf="!card.priority">
              {{card.id}}
            </span>
          </span>
          <span class="elapsed-time">
            {{_elapsedTimeStr}}
          </span>
        </div>
      </div>
      <!-- pm-card-footer -->
    </div>
  </div>
  <!-- pm-card-inner -->
</div>
<!-- pm-card -->