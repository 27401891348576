import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService, UtilService } from 'app/@core';
import { TM_Project, TM_ProjectTask, TM_Tag } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

const USER_CONFIG_PARAM_ID = "timer";

@Component({
  selector: 'timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
  className = 'TimerComponent';

  private destroy$: Subject<void> = new Subject<void>();
  private keyCode = 'Digit1';
  private keyPressed = true;
  public show = false;

  timerRef;
  syncData_serviceRef;

  oldWindowTitle = '';
  running = false;
  runnedTimeMs = 0;
  data = {
    h: 0,
    m: 0,
    s: 0,
    val: 0,
    timeStr: '00:00:00'
  };

  tmProjects: TM_Project[] = [];
  tmProjectTasks: TM_ProjectTask[] = [];
  tmTags: TM_Tag[] = [];

  constructor(
    public authService: AuthService,
    public uiFeedBackService: UiFeedBackService,
    public utilCtrl: UtilService
  ) { }
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.code == this.keyCode && event.altKey && event.shiftKey && !this.keyPressed) {
      this.keyPressed = true;
      this.toogleShow();
    }
  }
  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.code == this.keyCode) {
      this.keyPressed = false;
    }
  }
  toogleShow() {
    this.uiFeedBackService.presentLoader()
      .then(() => {
        this.authService.set_parameter(USER_CONFIG_PARAM_ID, !this.show)
          .then(() => {
            this.uiFeedBackService.dismissLoader();
          })
          .catch((e) => {
            this.uiFeedBackService.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
          });
      });
  }
  shouldShowPerformance() {
    return this.show;
  }
  ngOnInit() {
    this.oldWindowTitle = window.document.title;
    this.syncData_service();

    this.authService.getUserParametersChanges()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((userParameters) => {
        this.authService.get_parameter(USER_CONFIG_PARAM_ID)
          .then((data) => {
            const newShow = data ? data == true : false;
            if (newShow != this.show)
              this.show = newShow;
          })
          .catch((e) => {
            this.uiFeedBackService.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
          });
      });
  }
  ngOnDestroy() {
    clearInterval(this.timerRef);
    clearInterval(this.syncData_serviceRef);
    this.destroy$.next();
    this.destroy$.complete();
  }
  syncData_service() {
    this.syncData_serviceRef = setInterval(() => {
      if (this.running) {
        this.syncData();
        window.document.title = `${this.data.timeStr} | IC`;
      } else
        window.document.title = this.oldWindowTitle;
    });
  }
  syncData() {
    this.data.s = Math.floor((this.runnedTimeMs / 1000) % 60);
    this.data.m = Math.floor((this.runnedTimeMs / 1000 / 60) % 60);
    this.data.h = Math.floor((this.runnedTimeMs / 1000 / 3600) % 24);
    this.data.val = this.data.h + this.data.m / 60;
    this.data.timeStr = this.utilCtrl.formatters.millisecondsToHHMMSS(this.runnedTimeMs);
  }
  toggle() {
    this.running = !this.running;
    if (this.running) {
      const startTime = Date.now() - (this.runnedTimeMs || 0);
      this.timerRef = setInterval(() => {
        this.runnedTimeMs = Date.now() - startTime;
      });
    } else {
      clearInterval(this.timerRef);
    }
    console.log(`timer -> toggle:`, this.data);
  }
  reset() {
    clearInterval(this.timerRef);
    this.running = false;
    this.runnedTimeMs = 0;
    this.syncData();
    console.log(`timer -> reset:`, this.data);
  }
}
