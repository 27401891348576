import { Notification, PM_Board, PM_BoardActivity, PM_Card, PM_ToDo, User } from "app/@firebase";

function _createKey(): string {
    let d = new Date().getTime();
    const key = 'xxxxxxxx-Ixxxx-Cxxxx-xxxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return key;
};

// board
export function genNotif_boardAddMember(board: PM_Board, localUser: User, uNameAdded: string): Notification {
    return new Notification({
        id: _createKey(),
        uName: uNameAdded,

        status: 'info',
        module: 'PM',
        subModule: 'Kanban',

        senderName: localUser.fullName,
        senderPicture: localUser.thumbnail,
        senderTitle: localUser.jobTitle,

        action: 'url',
        actionBtnText: 'Ir para quadro',
        actionData: '/main/project-management/kanban/open-board?id=' + board.id,

        title: `👥 Você foi adicionado no quadro "${board.name}"!`,
        titleHtml: `<p>👥 Você foi adicionado no quadro "${board.name}"!</p>`,

        body: `${localUser.firstName} adicionou você no quadro "${board.name}".`,
        bodyHtml: `<p><b>${localUser.firstName}</b> adicionou você no quadro "${board.name}".</p>`,

        createdBy: localUser.uName,
    });
}
export function genNotif_boardRemMember(board: PM_Board, localUser: User, uNameRemoved: string): Notification {
    return new Notification({
        id: _createKey(),
        uName: uNameRemoved,

        status: 'danger',
        module: 'PM',
        subModule: 'Kanban',

        senderName: localUser.fullName,
        senderPicture: localUser.thumbnail,
        senderTitle: localUser.jobTitle,

        action: 'none',

        title: `🚫 Você foi removido do quadro "${board.name}"!`,
        titleHtml: `<p>🚫 Você foi removido do quadro "${board.name}"!</p>`,

        body: `${localUser.firstName} removeu você do quadro "${board.name}".`,
        bodyHtml: `<p><b>${localUser.firstName}</b> removeu você do quadro "${board.name}".</p>`,

        createdBy: localUser.uName,
    });
}
export function genNotif_boardExit(board: PM_Board, localUser: User, uNameToNotify: string): Notification {
    return new Notification({
        id: _createKey(),
        uName: uNameToNotify,

        status: 'warning',
        module: 'PM',
        subModule: 'Kanban',

        senderName: localUser.fullName,
        senderPicture: localUser.thumbnail,
        senderTitle: localUser.jobTitle,

        action: 'url',
        actionBtnText: 'Ir para quadro',
        actionData: '/main/project-management/kanban/open-board?id=' + board.id,

        title: `🚪 ${localUser.firstName} saiu do quadro "${board.name}"!`,
        titleHtml: `<p>🚪 ${localUser.firstName} saiu do quadro "${board.name}"!</p>`,

        body: `${localUser.firstName} não faz mais parte do quadro "${board.name}".`,
        bodyHtml: `<p><b>${localUser.firstName}</b> não faz mais parte do quadro "${board.name}".</p>`,

        createdBy: localUser.uName,
    });
}
// board

// card
export function genNotif_cardAddAssignee(card: PM_Card, localUser: User, uNameAdded: string): Notification {
    const cardTitle = card.title.length > 50 ? card.title.substring(0, 45) + '[...]' : card.title;
    let refStr = `${card.id} - ${cardTitle}`;
    if (card.priority)
        refStr = `[${card.priority}]${refStr}`;
    return new Notification({
        id: _createKey(),
        uName: uNameAdded,

        status: 'info',
        module: 'PM',
        subModule: 'Kanban',

        senderName: localUser.fullName,
        senderPicture: localUser.thumbnail,
        senderTitle: localUser.jobTitle,

        action: 'url',
        actionBtnText: 'Ir para cartão',
        actionData: '/main/project-management/kanban/open-board?id=' + card.boardId + '&cardIdToOpen=' + card.id,

        title: `👥 Você foi adicionado no cartão "${refStr}"!`,
        titleHtml: `<p>👥 Você foi adicionado no cartão "${refStr}"!</p>`,

        body: `${localUser.firstName} adicionou você no cartão "${refStr}".`,
        bodyHtml: `<p><b>${localUser.firstName}</b> adicionou você no cartão "${refStr}".</p>`,

        createdBy: localUser.uName,
    });
}
export function genNotif_cardRemAssignee(card: PM_Card, localUser: User, uNameRemoved: string): Notification {
    const cardTitle = card.title.length > 50 ? card.title.substring(0, 45) + '[...]' : card.title;
    let refStr = `${card.id} - ${cardTitle}`;
    if (card.priority)
        refStr = `[${card.priority}]${refStr}`;
    return new Notification({
        id: _createKey(),
        uName: uNameRemoved,

        status: 'danger',
        module: 'PM',
        subModule: 'Kanban',

        senderName: localUser.fullName,
        senderPicture: localUser.thumbnail,
        senderTitle: localUser.jobTitle,

        action: 'none',

        title: `🚫 Você foi removido no cartão "${refStr}"!`,
        titleHtml: `<p>🚫 Você foi removido do cartão "${refStr}"!</p>`,

        body: `${localUser.firstName} removeu você do cartão "${refStr}".`,
        bodyHtml: `<p><b>${localUser.firstName}</b> removeu você do cartão "${refStr}".</p>`,

        createdBy: localUser.uName,
    });
}
export function genNotif_cardComment(card: PM_Card, actv: PM_BoardActivity, localUser: User, uNameToNotify: string): Notification {
    const cardTitle = card.title.length > 50 ? card.title.substring(0, 45) + '[...]' : card.title;
    let refStr = `${card.id} - ${cardTitle}`;
    if (card.priority)
        refStr = `[${card.priority}]${refStr}`;
    let commentText = actv.text.length > 50 ? actv.text.substring(0, 45) + '[...]' : actv.text;
    return new Notification({
        id: _createKey(),
        uName: uNameToNotify,

        status: 'info',
        module: 'PM',
        subModule: 'Kanban',

        senderName: localUser.fullName,
        senderPicture: localUser.thumbnail,
        senderTitle: localUser.jobTitle,

        action: 'url',
        actionBtnText: 'Ir para cartão',
        actionData: '/main/project-management/kanban/open-board?id=' + card.boardId + '&cardIdToOpen=' + card.id + '&activityIdToFocus=' + actv.id,

        title: `💬 Novo comentário adicionado no cartão "${refStr}"!`,
        titleHtml: `<p>💬 Novo comentário adicionado no cartão "${refStr}"!</p>`,

        body: `${localUser.firstName} comentou: "${commentText}".`,
        bodyHtml: `<p><b>${localUser.firstName}</b> comentou: "${commentText}".</p>`,

        createdBy: localUser.uName,
    });
}
export function genNotif_cardCommentMention(card: PM_Card, actv: PM_BoardActivity, localUser: User, uNameToNotify: string): Notification {
    const cardTitle = card.title.length > 50 ? card.title.substring(0, 45) + '[...]' : card.title;
    let refStr = `${card.id} - ${cardTitle}`;
    if (card.priority)
        refStr = `[${card.priority}]${refStr}`;
    let commentText = actv.text.length > 50 ? actv.text.substring(0, 45) + '[...]' : actv.text;
    return new Notification({
        id: _createKey(),
        uName: uNameToNotify,

        status: 'info',
        module: 'PM',
        subModule: 'Kanban',

        senderName: localUser.fullName,
        senderPicture: localUser.thumbnail,
        senderTitle: localUser.jobTitle,

        action: 'url',
        actionBtnText: 'Ir para cartão',
        actionData: '/main/project-management/kanban/open-board?id=' + card.boardId + '&cardIdToOpen=' + card.id + '&activityIdToFocus=' + actv.id,

        title: `💬 Você foi mencionado em um comentário no cartão "${refStr}"!`,
        titleHtml: `<p>💬 Você foi mencionado em um comentário no cartão "${refStr}"!</p>`,

        body: `${localUser.firstName} mencionou você no comentário: "${commentText}".`,
        bodyHtml: `<p><b>${localUser.firstName}</b> mencionou você no comentário: "${commentText}".</p>`,

        createdBy: localUser.uName,
    });
}
export function genNotif_likeComment(card: PM_Card, actv: PM_BoardActivity, localUser: User, uNameToNotify: string): Notification {
    const cardTitle = card.title.length > 50 ? card.title.substring(0, 45) + '[...]' : card.title;
    let refStr = `${card.id} - ${cardTitle}`;
    if (card.priority)
        refStr = `[${card.priority}]${refStr}`;
    return new Notification({
        id: _createKey(),
        uName: uNameToNotify,

        status: 'success',
        module: 'PM',
        subModule: 'Kanban',

        senderName: localUser.fullName,
        senderPicture: localUser.thumbnail,
        senderTitle: localUser.jobTitle,

        action: 'url',
        actionBtnText: 'Ir para cartão',
        actionData: '/main/project-management/kanban/open-board?id=' + card.boardId + '&cardIdToOpen=' + card.id + '&activityIdToFocus=' + actv.id,

        title: `👍 ${localUser.firstName} achou seu comentário útil!`,
        titleHtml: `<p>👍 ${localUser.firstName} achou seu comentário útil!</p>`,

        body: `${localUser.firstName} achou seu comentário no cartão "${refStr}" útil.`,
        bodyHtml: `<p><b>${localUser.firstName}</b> achou seu comentário no cartão "${refStr}" útil.</p>`,

        createdBy: localUser.uName,
    });
}
export function genNotif_dislikeComment(card: PM_Card, actv: PM_BoardActivity, localUser: User, uNameToNotify: string): Notification {
    const cardTitle = card.title.length > 50 ? card.title.substring(0, 45) + '[...]' : card.title;
    let refStr = `${card.id} - ${cardTitle}`;
    if (card.priority)
        refStr = `[${card.priority}]${refStr}`;
    return new Notification({
        id: _createKey(),
        uName: uNameToNotify,

        status: 'danger',
        module: 'PM',
        subModule: 'Kanban',

        action: 'url',
        actionBtnText: 'Ir para cartão',
        actionData: '/main/project-management/kanban/open-board?id=' + card.boardId + '&cardIdToOpen=' + card.id + '&activityIdToFocus=' + actv.id,

        title: `👎 Alguém não achou seu comentário útil`,
        titleHtml: `<p>👎 Alguém <b>não</b> achou seu comentário útil</p>`,

        body: `Alguém não achou seu comentário no cartão "${refStr}" útil.`,
        bodyHtml: `<p>Alguém <b>não</b> achou seu comentário no cartão "${refStr}" útil.</p>`,

        createdBy: localUser.uName,
    });
}
// card

// todo
export function genNotif_todoAssing(card: PM_Card, todo: PM_ToDo, localUser: User): Notification {
    const cardTitle = card.title.length > 50 ? card.title.substring(0, 45) + '[...]' : card.title;
    let refStr = `${card.id} - ${cardTitle}`;
    if (card.priority)
        refStr = `[${card.priority}]${refStr}`;
    let todoText = todo.title.length > 50 ? todo.title.substring(0, 45) + '[...]' : todo.title;
    return new Notification({
        id: _createKey(),
        uName: todo.assignedTo,

        status: 'info',
        module: 'PM',
        subModule: 'Kanban',

        senderName: localUser.fullName,
        senderPicture: localUser.thumbnail,
        senderTitle: localUser.jobTitle,

        action: 'url',
        actionBtnText: 'Ir para cartão',
        actionData: '/main/project-management/kanban/open-board?id=' + card.boardId + '&cardIdToOpen=' + card.id + '&toDoIdToFocus=' + todo.id,

        title: `📋 Uma nova atividade foi atribuída a você no cartão "${refStr}"!`,
        titleHtml: `<p>📋 Uma nova atividade foi atribuída a você no cartão "${refStr}"!</p>`,

        body: `${localUser.firstName} atribuiu você a "${todoText}" no cartão "${card.title}".`,
        bodyHtml: `<p><b>${localUser.firstName}</b> atribuiu você a "<b>${todoText}</b>" no cartão "<b>${card.title}</b>".</p>`,

        createdBy: localUser.uName,
    });
}
// todo
