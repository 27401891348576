<div #popdownContent>
  <nb-card status="success">
    <nb-card-header class="custon-nb-card-header-header">
      <p>Preferencias do Usuário</p>
      <button class="close" mat-button matDialogClose>
        <span aria-hidden="true">&times;</span>
      </button>
    </nb-card-header>
    <nb-card-body>

      <div class="row">
        <div class="col-12">
          <input [(ngModel)]="localDoc.showSaveDoneAlert" (ngModelChange)="_checkForChanges()" type="checkbox"
            id="input_showSaveDoneAlert" name="input_showSaveDoneAlert">
          <label for="input_showSaveDoneAlert">Mostrar Confirmação ao salvar</label>
        </div>

        <div class="col-12">
          <input [(ngModel)]="localDoc.showWeekend" (ngModelChange)="_showWeekendInputModelChange()" type="checkbox"
            id="input_showWeekend" name="input_showWeekend">
          <label for="input_showWeekend">Mostrar Sábado e Domingo</label>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-12">
          <input [(ngModel)]="localDoc.weeklyGoal.active" (ngModelChange)="_checkForChanges()"
            (ngModelChange)="_checkErrors()" type="checkbox" id="input_weeklyGoal_active"
            name="input_weeklyGoal_active">
          <label for="input_weeklyGoal_active">Meta Semanal</label>
        </div>

        <div class="col-12" *ngIf="localDoc.weeklyGoal.active">
          <p style="padding-left: 5px;">Dias da semana:</p>
          <!-- table -->
          <table class="timer-table">
            <thead>
              <tr>
                <th style="width: 65px;" class="th-day" *ngFor="let d of localDoc.weeklyGoal.days; let th_day_i=index">
                  <nb-icon *ngIf="_dayHasError(th_day_i)" icon="alert-triangle-outline" pack="eva" style="color: red;">
                  </nb-icon>
                  <p>{{weekDayNames[th_day_i]}}</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- Lines -->
              <tr class="line">
                <td *ngFor="let d of localDoc.weeklyGoal.days; let days_i=index" class="input-time-container"
                  [ngClass]="{'nil': localDoc.weeklyGoal.days[days_i].val == 0}">
                  <input [disabled]="(days_i == 0 || days_i == 6) && !localDoc.showWeekend"
                    (ngModelChange)="_dayInputModelChange(days_i)" (blur)="_dayInputBlur( days_i)"
                    [(ngModel)]="localDoc.weeklyGoal.days[days_i].time" [id]="'input_min_'+days_i"
                    [name]="'input_min_'+days_i" type="time" step="0" max="24:00" />
                </td>
              </tr>
              <!-- Lines -->
            </tbody>
          </table>
          <!-- table -->
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-12 editor-wizard">
          <div *ngIf="errors.length>0">
            <p>❌ Erros de Preenchimento:</p>
            <div *ngFor="let e of errors; let i=index">
              <p class="error"><b>{{i+1}})</b> {{e}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12" style="text-align: center">
          <div class="button-container">
            <button nbButton color="primary" style="font-weight: bold;" [disabled]="!hasChanges ||  errors.length > 0"
              (click)="save()">
              <nb-icon icon="save-outline" pack="eva"></nb-icon>
              Salvar Preferencias
            </button>
          </div>
        </div>
      </div>

    </nb-card-body>
  </nb-card>
</div>