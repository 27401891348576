import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { NotificationsListComponent } from "./notifications-list/notifications-list.component";
import { PerformanceMeasurementDisplayComponent } from "./performance-measurement-display/performance-measurement-display.component";
import { SearchInputComponent } from "./search-input/search-input.component";
import { SwitcherComponent } from "./switcher/switcher.component";
import { ThemeSwitcherComponent } from "./theme-switcher/theme-switcher.component";
import { TimerComponent } from "./timer/timer.component";

export const COMPONENTS_COMPONENTS = [
    SwitcherComponent,
    SearchInputComponent,
    HeaderComponent,
    PerformanceMeasurementDisplayComponent,
    ThemeSwitcherComponent,
    TimerComponent,
    FooterComponent,
    NotificationsListComponent
];
export const COMPONENTS_MODULES = [
];