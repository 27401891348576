<div *ngIf="show" class="timer">
  <!-- duration -->
  <div class="duration">
    <span>
      {{data.timeStr}}
    </span>
  </div>
  <!-- duration -->

  <!-- buttons -->
  <div class="buttons-container">
    <button *ngIf="!running" class="play-btn" title="Start timer" (click)="toggle()">
      <nb-icon icon="play-circle-outline" pack="eva"></nb-icon>
    </button>

    <button *ngIf="running" class="stop-btn" title="Stop timer" (click)="toggle()">
      <nb-icon icon="stop-circle-outline" pack="eva"></nb-icon>
    </button>

    <button *ngIf="runnedTimeMs > 0" class="reset-btn" title="Reset timer" (click)="reset()">
      <nb-icon icon="refresh-outline" pack="eva"></nb-icon>
    </button>
  </div>
  <!-- buttons -->
</div>