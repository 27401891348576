<div id="{{className}}" class="body-section to-do-lists">

    <div *ngIf="_loader" class="loader">
        <p>Carregando...</p>
    </div>

    <div cdkDropList [cdkDropListData]="pmCardModal.card.toDoListsIds" (cdkDropListDropped)="_toDolistsOnDrop($event)">
        <div class="body-section-header">
            <nb-icon class="header-icon" icon="checkmark-square-2-outline" pack="eva"></nb-icon>
            <span class="header-title">To Do Lists</span>
            <div class="ic-button-grp">
                <button [disabled]="toDoListsIds.length == 0" nbButton title="Copiar para a clipboard"
                    appearance="ghost" size="small" (click)="_copyToDoListsToClipboard()">
                    <nb-icon icon="copy-outline" pack="eva"></nb-icon>
                </button>
                <button [disabled]="!pmCardModal.isBoardActive" nbButton title="Fazer Upload da clipboard"
                    appearance="ghost" size="small" (click)="_useClipboardToCreateToDoLists()">
                    <nb-icon icon="clipboard-outline" pack="eva"></nb-icon>
                </button>
                <button [disabled]="!pmCardModal.isBoardActive" title="Novo To Do List" nbButton size="small"
                    appearance="ghost" (click)="_addToDoList()">
                    <nb-icon icon="plus-circle-outline" pack="eva"></nb-icon>
                </button>
            </div>
        </div>

        <!-- cdkDrag -->
        <div id="{{toDoListId}}" *ngFor="let toDoListId of pmCardModal.card.toDoListsIds; trackBy: _trackByFn"
            class="pm-card-modal-to-do-list" cdkDrag
            [cdkDragDisabled]="!pmCardModal.isBoardActive || !cardToDoListsMap[toDoListId]">
            <div *ngIf="cardToDoListsMap[toDoListId]">
                <div class="to-do-list-header">
                    <div class="to-do-list-title">
                        <input [disabled]="!pmCardModal.isBoardActive || dragingToDo"
                            class="card-title realtime-editible-input" type="text"
                            id="{{'Input_to-do-list_title_' + toDoListId}}"
                            name="{{'Input_to-do-list_title_' + toDoListId}}" placeholder="Título da lista"
                            autocomplete="nope" [(ngModel)]="cardToDoListsMap[toDoListId].title"
                            (keyup.enter)="_updateToDoListAfterChange(cardToDoListsMap[toDoListId])"
                            (blur)="_updateToDoListAfterChange(cardToDoListsMap[toDoListId])">

                        <div class="ic-button-grp">
                            <button [disabled]="cardToDoListsMap[toDoListId].toDosIds.length == 0" nbButton
                                title="Copiar para a clipboard" appearance="ghost" size="small"
                                (click)="_copyToDoListToClipboard(cardToDoListsMap[toDoListId])">
                                <nb-icon icon="copy-outline" pack="eva"></nb-icon>
                            </button>

                            <button [disabled]="!pmCardModal.isBoardActive" nbButton title="Fazer Upload da clipboard"
                                appearance="ghost" size="small"
                                (click)="_useClipboardToCreateToDoList(cardToDoListsMap[toDoListId])">
                                <nb-icon icon="clipboard-outline" pack="eva"></nb-icon>
                            </button>

                            <button [disabled]="!pmCardModal.isBoardActive" cdkDragHandle title="Mover" nbButton
                                appearance="ghost" size="small">
                                <nb-icon icon="move-outline" pack="eva"></nb-icon>
                            </button>

                            <button [disabled]="!pmCardModal.isBoardActive" nbButton title="Apagar" status="danger"
                                appearance="ghost" size="small" (click)="_remToDoList(cardToDoListsMap[toDoListId])">
                                <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="_toDoListsDonePercMap[toDoListId]" class="to-do-list-progress">
                        <span class="progress-text">
                            {{_toDoListsDonePercMap[toDoListId].toFixed(2)}}%
                        </span>
                        <div class="progress">
                            <span class="progress-bar" [style.width]="_toDoListsDonePercMap[toDoListId]+'%'"></span>
                        </div>
                    </div>
                </div>

                <!-- list -->
                <div [id]="toDoListId" class="list" cdkDropList
                    [cdkDropListDisabled]="!pmCardModal.isBoardActive || !cardToDoListsMap[toDoListId]"
                    [cdkDropListConnectedTo]="toDoListsIds" [cdkDropListData]="cardToDoListsMap[toDoListId].toDosIds"
                    (cdkDropListDropped)="_todolistOnDrop($event)">

                    <!-- cdkDrag -->
                    <div *ngFor="let toDoId of cardToDoListsMap[toDoListId].toDosIds; trackBy: _trackByFn"
                        id="{{toDoId}}" class="pm-card-modal-to-do" cdkDrag
                        [cdkDragDisabled]="!pmCardModal.isBoardActive || !cardToDosMap[toDoId]" [cdkDragData]="toDoId"
                        (cdkDragStarted)="_onDragToDoStarted($event)" (cdkDragEnded)="_onDragToDoEnded($event)">

                        <div *ngIf="pmCardModal.board.integration.tm.active && timeRecordMap[toDoId]"
                            title="Somatório de horas apontadas neste ToDo" class="total-hours-badge">
                            <nb-icon icon="clock-outline" pack="eva"></nb-icon>
                            <span>{{timeRecordMap[toDoId]}}</span>
                        </div>

                        <div class="inner" *ngIf="cardToDosMap[toDoId]">
                            <button class="done-btn" nbButton [disabled]="!pmCardModal.isBoardActive" appearance="ghost"
                                size="large" title="Marcar/Desmarcar como concluído"
                                (click)="_toggleToDoDone(cardToDosMap[toDoId])">
                                <nb-icon *ngIf="!cardToDosMap[toDoId].done" icon="radio-button-off-outline"
                                    pack="eva"></nb-icon>
                                <nb-icon *ngIf="cardToDosMap[toDoId].done" icon="checkmark-circle-2" pack="eva"
                                    status="success"></nb-icon>
                            </button>

                            <input id="{{'Input_to-do_title_'+toDoListId}}" name="{{'Input_to-do_title_'+toDoListId}}"
                                class="realtime-editible-input small" type="text"
                                [disabled]="!pmCardModal.isBoardActive" placeholder="Título" autocomplete="nope"
                                [(ngModel)]="cardToDosMap[toDoId].title"
                                (keyup.enter)="_updateToDoAfterChange(cardToDosMap[toDoId])"
                                (blur)="_updateToDoAfterChange(cardToDosMap[toDoId])">

                            <div class="ic-button-grp">
                                <button *ngIf="pmCardModal.board.integration.tm.active" nbButton
                                    title="Apontar Horas No ToDo" size="tiny" appearance="ghost" status="primary"
                                    (click)="pmCardModal._tmIntegrationToDo(cardToDosMap[toDoId])">
                                    <nb-icon icon="clock-outline" pack="eva"></nb-icon>
                                </button>

                                <button nbButton title="Copiar Link" size="tiny" appearance="ghost"
                                    (click)="_copyToDoLink(cardToDosMap[toDoId])">
                                    <nb-icon icon="link-2-outline" pack="eva"></nb-icon>
                                </button>

                                <button id="{{'to-do-due-date-popdown-trigger-' + toDoId}}"
                                    [disabled]="!pmCardModal.isBoardActive" title="Alterar Prazo" nbButton
                                    appearance="ghost" size="tiny" (click)="_showToDoDueDatePopdown(toDoId)"
                                    class="to-do-due-date-btn">
                                    <div class="inner" title="{{cardToDosDueDateStatusMap[toDoId].name}}"
                                        [style.color]="cardToDosDueDateStatusMap[toDoId].color"
                                        [style.background-color]="cardToDosDueDateStatusMap[toDoId].background_color"
                                        [style.border-color]="cardToDosDueDateStatusMap[toDoId].background_color">
                                        <nb-icon *ngIf="!cardToDosDueDateDisplayStrMap[toDoId]" icon="calendar-outline"
                                            pack="eva"></nb-icon>
                                        <span class="due-date" *ngIf="cardToDosDueDateDisplayStrMap[toDoId]">
                                            {{cardToDosDueDateDisplayStrMap[toDoId]}}
                                        </span>
                                    </div>
                                </button>
                                <!-- To do Due Date Popdown -->
                                <div id="{{'to-do-due-date-popdown-' + toDoId}}" class="popdown to-do-due-date-popdown"
                                    [ngClass]="{'hide': showToDoDueDatePopdownId != toDoId}">
                                    <div *ngIf="showToDoDueDatePopdownId == toDoId" class="popdownContent">
                                        <div class="popdown-body">
                                            <!-- dates -->
                                            <div class="popdown-row">
                                                <!-- startDateString -->
                                                <div class="popdown-col">
                                                    <button
                                                        [ngClass]="{'hide': cardToDosMap[toDoId].startDateString != null}"
                                                        [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)"
                                                        (click)="_toDoDueDatePopdown_toggleStartDate(cardToDosMap[toDoId])">
                                                        <nb-icon status="primary" icon="plus-circle-outline"
                                                            pack="eva"></nb-icon>
                                                        <span>
                                                            Data de Início
                                                        </span>
                                                    </button>

                                                    <div [ngClass]="{'hide': cardToDosMap[toDoId].startDateString == null}"
                                                        class="input-row">
                                                        <input id="{{'toDo-startDateStringInput-' + toDoId}}"
                                                            name="{{'toDo-startDateStringInput-' + toDoId}}"
                                                            title="Início"
                                                            class="ic-form-control realtime-editible-input" type="date"
                                                            [(ngModel)]="cardToDosMap[toDoId].startDateString"
                                                            (ngModelChange)="_toDoDueDatePopdown_startDateStringInputOnChange(cardToDosMap[toDoId])"
                                                            [min]="pmCardModal.card.startDateString"
                                                            [max]="pmCardModal.card.dueDateString"
                                                            [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)">

                                                        <button
                                                            [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)"
                                                            (click)="_toDoDueDatePopdown_toggleStartDate(cardToDosMap[toDoId])">
                                                            <nb-icon size="tiny" status="danger"
                                                                icon="close-circle-outline" pack="eva"></nb-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                                <!-- startDateString -->

                                                <!-- cardDueDateString -->
                                                <div class="popdown-col">
                                                    <input id="{{'toDo-dueDateStringInput-' + toDoId}}"
                                                        name="{{'toDo-dueDateStringInput-' + toDoId}}" title="Fim"
                                                        class="ic-form-control realtime-editible-input" type="date"
                                                        [(ngModel)]="cardToDosMap[toDoId].dueDateString"
                                                        (ngModelChange)="_toDoDueDatePopdown_dueDateStringInputOnChange(cardToDosMap[toDoId])"
                                                        [min]="pmCardModal.card.startDateString"
                                                        [max]="pmCardModal.card.dueDateString"
                                                        [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)">
                                                </div>
                                                <!-- cardDueDateString -->
                                            </div>
                                            <!-- dates -->

                                            <!-- hours -->
                                            <div class="popdown-row"
                                                [ngClass]="{'hide': cardToDosMap[toDoId].dueDateHourString == null}">
                                                <!-- startDateHourString -->
                                                <div class="popdown-col">
                                                    <button
                                                        [ngClass]="{'hide': (cardToDosMap[toDoId].startDateString == '' || cardToDosMap[toDoId].startDateString == null) || cardToDosMap[toDoId].startDateHourString != null}"
                                                        [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin) || cardToDosMap[toDoId].dueDate == null"
                                                        (click)="_toDoDueDatePopdown_toggleStartDateHour(cardToDosMap[toDoId])">
                                                        <nb-icon status="primary" icon="plus-circle-outline"
                                                            pack="eva"></nb-icon>
                                                        <span>
                                                            Hora de Início
                                                        </span>
                                                    </button>

                                                    <div [ngClass]="{'hide': cardToDosMap[toDoId].startDateHourString == null}"
                                                        class="input-row">
                                                        <select id="{{'toDo-startDateHourStringInput-' + toDoId}}"
                                                            name="{{'toDo-startDateHourStringInput-' + toDoId}}"
                                                            title="Prazo"
                                                            class="ic-form-control realtime-editible-input"
                                                            [(ngModel)]="cardToDosMap[toDoId].startDateHourString"
                                                            (ngModelChange)="_toDoDueDatePopdown_startDateStringInputOnChange(cardToDosMap[toDoId])"
                                                            [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)">
                                                            <option *ngFor="let time of TIMES" [ngValue]="time">{{time}}
                                                            </option>
                                                        </select>

                                                        <button
                                                            [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin) || cardToDosMap[toDoId].dueDate == null"
                                                            (click)="_toDoDueDatePopdown_toggleStartDateHour(cardToDosMap[toDoId])">
                                                            <nb-icon size="tiny" status="danger"
                                                                icon="close-circle-outline" pack="eva"></nb-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                                <!-- startDateHourString -->

                                                <!-- dueDateHourString -->
                                                <div class="popdown-col">
                                                    <select id="{{'toDo-dueDateHourStringInput-' + toDoId}}"
                                                        name="{{'toDo-dueDateHourStringInput-' + toDoId}}" title="Prazo"
                                                        class="ic-form-control realtime-editible-input"
                                                        [(ngModel)]="cardToDosMap[toDoId].dueDateHourString"
                                                        (ngModelChange)="_toDoDueDatePopdown_dueDateHourStringInputOnChange(cardToDosMap[toDoId])"
                                                        [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)">
                                                        <option *ngFor="let time of TIMES" [ngValue]="time">{{time}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <!-- dueDateHourString -->
                                            </div>
                                            <!-- hours -->
                                        </div>
                                        <hr>
                                        <div class="popdown-footer">
                                            <button title="Adicionar Hora"
                                                [ngClass]="{'active': cardToDosMap[toDoId].dueDateHourString != null}"
                                                [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin) || cardToDosMap[toDoId].dueDate == null"
                                                (click)="_toDoDueDatePopdown_toggleDueDateHour(cardToDosMap[toDoId])">
                                                <nb-icon icon="clock-outline" pack="eva"></nb-icon>
                                            </button>

                                            <button title="Limpar"
                                                [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)"
                                                (click)="_toDoDueDatePopdown_clear(cardToDosMap[toDoId])">
                                                <span>Limpar</span>
                                            </button>
                                        </div>
                                        <p *ngIf="pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin"
                                            class="tiny-error">
                                            Somente admins podem alterar prazos
                                        </p>
                                    </div>
                                </div>
                                <!-- To do Due Date Popdown -->

                                <button id="{{'to-do-assignee-popdown-trigger-' + toDoId}}"
                                    [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeCardMembers && !pmCardModal.isUserAdmin)"
                                    class="to-do-assignee-btn"
                                    [ngClass]="{'has-user': cardToDosAssigneesMap[toDoId] && cardToDosAssigneesMap[toDoId].id != ''}"
                                    title="Alterar Responsável" nbButton appearance="ghost" size="tiny"
                                    (click)="_showToDoAssigneePopdown(toDoId)">
                                    <nb-icon
                                        *ngIf="!cardToDosAssigneesMap[toDoId] || cardToDosAssigneesMap[toDoId].id == ''"
                                        icon="person-add-outline" pack="eva"></nb-icon>

                                    <nb-user
                                        *ngIf="cardToDosAssigneesMap[toDoId] && cardToDosAssigneesMap[toDoId].id != ''"
                                        [name]="cardToDosAssigneesMap[toDoId].fullName"
                                        [title]="cardToDosAssigneesMap[toDoId].jobTitle"
                                        [picture]="cardToDosAssigneesMap[toDoId].thumbnail" onlyPicture>
                                    </nb-user>
                                </button>
                                <!-- To do Assignee Popdown -->
                                <div id="{{'to-do-assignee-popdown-' + toDoId}}" class="popdown to-do-assignee-popdown"
                                    [ngClass]="{'hide': showToDoAssigneePopdownId != toDoId}">
                                    <div *ngIf="showToDoAssigneePopdownId == toDoId" class="popdownContent">
                                        <div class="popdown-body">
                                            <div class="input-row">

                                                <ng-select id="toDoAssignee" name="toDoAssignee" required
                                                    [(ngModel)]="cardToDosAssigneesMap[toDoId]"
                                                    (keyup.enter)="_updateToDoAssigneeAfterChange(cardToDosMap[toDoId], cardToDosAssigneesMap[toDoId])"
                                                    (ngModelChange)="_updateToDoAssigneeAfterChange(cardToDosMap[toDoId], cardToDosAssigneesMap[toDoId])">
                                                    <ng-option *ngFor="let boardMember of pmCardModal.boardMembers"
                                                        [value]="boardMember">
                                                        <nb-user [name]="boardMember.fullName"
                                                            [title]="boardMember.jobTitle"
                                                            [picture]="boardMember.thumbnail">
                                                        </nb-user>
                                                    </ng-option>
                                                </ng-select>

                                                <button [disabled]="cardToDosMap[toDoId].assignedTo == ''" nbButton
                                                    status="danger" appearance="ghost" size="tiny"
                                                    (click)="_cleanToDoAssignee(cardToDosMap[toDoId])">
                                                    <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- To do Assignee Popdown -->

                                <button [disabled]="!pmCardModal.isBoardActive" cdkDragHandle title="Mover" nbButton
                                    appearance="ghost" size="tiny">
                                    <nb-icon icon="move-outline" pack="eva"></nb-icon>
                                </button>

                                <button [disabled]="!pmCardModal.isBoardActive" nbButton status="danger"
                                    appearance="ghost" size="tiny"
                                    (click)="_remToDo(cardToDoListsMap[toDoListId], cardToDosMap[toDoId])">
                                    <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
                                </button>
                            </div>
                        </div>

                        <div class="inner-loader" *ngIf="!cardToDosMap[toDoId]">
                            <span>Carregando...</span>
                        </div>
                    </div>
                    <!-- cdkDrag -->

                </div>
                <!-- list -->

                <!-- new to do -->
                <div class="new-to-do-list">
                    <button [disabled]="!pmCardModal.isBoardActive" nbButton size="small"
                        (click)="_addToDo(cardToDoListsMap[toDoListId])">
                        <nb-icon icon="plus-circle-outline" pack="eva"></nb-icon>
                        Novo To Do
                    </button>
                </div>
                <!-- new to do -->

            </div>
            <div class="inner-loader" *ngIf="!cardToDoListsMap[toDoListId]">
                <span>Carregando...</span>
            </div>
            <!-- cdkDrag -->
        </div>
    </div>
</div>