<div class="timer-list-tab">

  <!-- table -->
  <nb-card [nbSpinner]="loader">
    <nb-card-body class="table-card">

      <!-- timer-table -->
      <div class="timer-table">
        <!-- timer-table-header -->
        <div class="timer-table-header">
          <div class="timer-table-header-col number">
            <nb-icon icon="hash-outline" pack="eva"></nb-icon>
            <!-- <p>Nº</p> -->
          </div>

          <div class="timer-table-header-col project">
            <nb-icon icon="folder-outline" pack="eva"></nb-icon>
            <p>Projeto</p>
          </div>

          <div class="timer-table-header-col task">
            <nb-icon icon="list-check" pack="fas"></nb-icon>
            <p>Tarefa</p>
          </div>

          <div class="timer-table-header-col tags">
            <nb-icon icon="tag" pack="fas"></nb-icon>
            <p>Tags</p>
          </div>

          <div class="timer-table-header-col description">
            <nb-icon icon="align-left" pack="fas"></nb-icon>
            <p>Descrição</p>
          </div>

          <div class="timer-table-header-col day" *ngFor="let d of days; let th_day_i=index"
            [ngClass]="{'today': isToday(d),'isWeekendDay': d.isWeekendDay}">
            <p class="weekDay">
              <nb-icon *ngIf="_dayHasError(th_day_i)" title="Dia com erros de preenchimento!"
                icon="alert-triangle-outline" pack="eva" status="danger">
              </nb-icon>
              <span>{{d.weekDay}}</span>
            </p>
            <p class="dateStr">{{d.dateStr}}</p>
          </div>

          <div class="timer-table-header-col options" *ngIf="edit">
            <button title="Nova Linha (Alt + Shift + N)" nbButton appearance="ghost" status="primary"
              (click)="addLine()">
              <nb-icon icon="file-add-outline" pack="eva"></nb-icon>
            </button>
          </div>
        </div>
        <!-- timer-table-header -->

        <!-- timer-table-body -->
        <cdk-virtual-scroll-viewport itemSize="40" minBufferPx="80" maxBufferPx="160" id="timer-table-body"
          class="timer-table-body">
          <!-- Lines -->
          <div [id]="'row_' + line_index" *cdkVirtualFor="let line of lines; let line_index=index"
            class="timer-table-line" (click)="selectLine(line_index)"
            [ngClass]="{'first': line_index == 0, 'second': line_index == 1, 'third': line_index == 2, 'penultimate': line_index == lines.length - 2, 'last': line_index == lines.length - 1, 'selected': line_index == selectedLineIndex, 'project_inactive': line.project.status == 0,'no_auth': !line.auth, 'managed': line.managed, 'create_by_integration': line.referenceType, 'old': line.status == 'old', 'new': line.status == 'new', 'changed': line.status == 'changed'}">

            <!-- Nº -->
            <div class="timer-table-cell number">

              <nb-icon *ngIf="line.hasError" title="Linha com erros de preenchimento!" icon="alert-triangle-outline"
                pack="eva" status="danger"></nb-icon>

              <p *ngIf="line.status == 'old'">
                {{line_index + 1}}
              </p>

              <p *ngIf="line.status == 'new' || line.status == 'changed'">
                <b> {{line_index+1}}* </b>
              </p>

              <!-- tooltip-managed -->
              <div class="tooltip-box">
                <span class="tooltip-content tooltip-managed">
                  <nb-icon icon="alert-triangle-outline" pack="eva" status="warning"></nb-icon>
                  Linha criada / alterada via gerenciamento de horas
                </span>
              </div>
              <!-- tooltip-managed -->
              <!-- tooltip-project_inactive -->
              <div class="tooltip-box">
                <span class="tooltip-content tooltip-project_inactive">
                  <nb-icon icon="alert-triangle-outline" pack="eva" status="warning"></nb-icon>
                  Projeto: "{{line.project.teamId}} - {{line.project.name}}" inativo!
                </span>
              </div>
              <!-- tooltip-project_inactive -->
              <!-- tooltip-no_auth -->
              <div class="tooltip-box">
                <span class="tooltip-content tooltip-no_auth">
                  <nb-icon icon="alert-triangle-outline" pack="eva" status="warning"></nb-icon>
                  Sem autorização ao projeto: "{{line.project.teamId}} - {{line.project.name}}"!
                </span>
              </div>
              <!-- tooltip-no_auth -->
            </div>

            <!-- Projeto -->
            <div class="timer-table-cell project"
              [style.background-color]="db.DICTIONARY.getData(line.project.colorId, db.DICTIONARY.timeManagement.project.color)['hex']">
              <select [disabled]="!edit || !line.auth || line.project.status == 0" [id]="'input_project_' + line_index"
                [name]="'input_project_' + line_index" [(ngModel)]="line.project" [compareWith]="_compareFn"
                (ngModelChange)="_selectProject(line_index)">
                <option *ngFor="let p of selectionData.projects" [ngValue]="p"
                  [style.color]="db.DICTIONARY.getData(p.colorId, db.DICTIONARY.timeManagement.project.color)['hex']">
                  {{p.teamId}} - {{p.name}}
                </option>
              </select>
            </div>

            <!-- Tarefa -->
            <div class="timer-table-cell task"
              [style.background-color]="db.DICTIONARY.getData(line.project.colorId, db.DICTIONARY.timeManagement.project.color)['hex']">

              <select *ngIf="!line.project.taskControl" disabled [id]="'input_project_' + line_index"
                [name]="'input_project_' + line_index">
                <option selected>
                  N/A
                </option>
              </select>

              <select *ngIf="line.project.taskControl"
                [disabled]="!edit || !line.auth || line.project.status == 0  || !line.project.taskControl"
                [id]="'input_project_' + line_index" [name]="'input_project_' + line_index" [(ngModel)]="line.task"
                [compareWith]="_compareFn" (ngModelChange)="_selectTask(line_index)">
                <option *ngFor="let t of line.selectionData.projectTasks" [ngValue]="t">
                  {{t.name}}
                </option>
              </select>
            </div>

            <!-- Tags -->
            <div class="timer-table-cell tags"
              [style.background-color]="db.DICTIONARY.getData(line.project.colorId, db.DICTIONARY.timeManagement.project.color)['hex']">
              <div class="tags-list">
                <button class="tag" [ngClass]="{'project-task-tag': line.task.tagId == tag.id}"
                  *ngFor="let tag of line.tags; let tag_i=index" title="Clique para remover"
                  [disabled]="!edit || line.project.status == 0 || !line.auth"
                  (click)="_remTag(tag, tag_i, line_index)">
                  <span>{{tag.name}}</span>
                </button>

                <button class="tag add-tag"
                  [disabled]="!edit || line.project.status==0 || !line.auth  || showTagPopdown == line_index || line.selectionData.projectTags.length == 0 || line.tags.length == line.selectionData.projectTags.length || (line.project.taskControl && line.task.id == '')"
                  (click)="_openTagPopdown(line_index)">
                  <nb-icon icon="plus-outline" status="primary" pack="eva"></nb-icon>
                </button>
              </div>

              <!-- tags-popdown -->
              <div class="popdown tags-popdown" *ngIf="showTagPopdown == line_index">
                <div class="tags-popdown-content" #TagPopdownContentERef>
                  <ul>
                    <li *ngFor="let tag of _getTagsForShow(line_index)">
                      <button class="tag" title="Clique para adicionar" (click)="_selectTag(tag, line_index)">
                        <span>{{tag.name}}</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- tags-popdown -->
            </div>

            <!-- Descrição -->
            <div class="timer-table-cell description"
              [style.background-color]="db.DICTIONARY.getData(line.project.colorId, db.DICTIONARY.timeManagement.project.color)['hex']">
              <input class="input-description" [disabled]="!edit || !line.auth || line.project.status == 0" type="text"
                id="{{'input_description_'+line_index}}" name="{{'input_description_'+line_index}}"
                [(ngModel)]="line.description" autocomplete="nope" Placeholder="Descrição"
                (ngModelChange)="_updateDescription(line_index)">
            </div>

            <!-- day -->
            <div class="timer-table-cell day" *ngFor="let d of days; let days_index=index"
              [style.background-color]="db.DICTIONARY.getData(line.project.colorId, db.DICTIONARY.timeManagement.project.color)['hex']"
              [ngClass]="{'zero': line.days[days_index].val == 0, 'managed': line.days[days_index].managed && line.days[days_index].val > 0, 'today': isToday(d)}">
              <input [disabled]="!edit || !line.auth || line.project.status == 0" class="type_time"
                (ngModelChange)="_dayInputModelChange(line_index, days_index)"
                (blur)="_dayInputBlur(line_index, days_index)" [(ngModel)]="line.days[days_index].time"
                id="{{'input_time_'+line_index+':'+days_index}}" name="{{'input_time_'+line_index+':'+days_index}}"
                type="time" step="0" max="24:00" />
            </div>

            <!-- Options -->
            <div class="timer-table-cell options" *ngIf="edit">
              <button [disabled]="!line.auth || line.project.status == 0" title="Opções" nbButton appearance="ghost"
                status="primary" (click)="_openOptionsPopdown(line_index)">
                <nb-icon icon="more-vertical-outline" pack="eva" status="basic"></nb-icon>
              </button>

              <!-- options-popdown -->
              <div class="popdown options-popdown" *ngIf="showOptionsPopdown == line_index">
                <div class="options-popdown-content" #OptionPopdownContentERef>
                  <ul>
                    <li *ngIf="line.referenceType">
                      <button nbButton size="small" status="primary" appearance="ghost" title="Abrir Link"
                        (click)="_openIntegration(line_index)">
                        <nb-icon icon="external-link-outline" pack="eva"></nb-icon>
                        Abrir Link
                      </button>
                    </li>
                    <li>
                      <button nbButton size="small" status="primary" appearance="ghost"
                        title="Duplicar (Alt + Shift + D)" (click)="_dupLine(line_index)">
                        <nb-icon icon="copy-outline" pack="eva"></nb-icon>
                        Duplicar
                      </button>
                    </li>
                    <li>
                      <button nbButton size="small" status="danger" appearance="ghost" title="Deletar (Delete)"
                        (click)="_remLine(line_index)">
                        <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
                        Deletar
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- options-popdown -->
            </div>
          </div>
          <!-- Lines -->

          <!-- integration -->
          <div *ngIf="integrationActivate" class="timer-table-line integration-line">
            <!-- Nº -->
            <div class="timer-table-cell number">
              <button title="Nova Linha (Integração)" *ngIf="edit" nbButton status="warning" appearance="ghost"
                (click)="addLineFromIntegrationData()">
                <nb-icon icon="star-outline" pack="eva"></nb-icon>
              </button>
            </div>

            <!-- Projeto -->
            <div class="timer-table-cell project" *ngIf="!integrationData.presetProjectId"></div>

            <div class="timer-table-cell project" *ngIf="integrationData.presetProjectId"
              [style.background-color]="db.DICTIONARY.getData(integrationPresetProject.colorId, db.DICTIONARY.timeManagement.project.color)['hex']">
              <select disabled [(ngModel)]="integrationData.presetProjectId">
                <option *ngFor="let p of dataCore.projects" [ngValue]="p.id">
                  {{p.teamId}} - {{p.name}}
                </option>
              </select>
            </div>
            <!-- Projeto -->

            <!-- Tarefa -->
            <div class="timer-table-cell task"
              [style.background-color]="db.DICTIONARY.getData(integrationPresetProject.colorId, db.DICTIONARY.timeManagement.project.color)['hex']">
            </div>

            <!-- Tags -->
            <div class="timer-table-cell tags"
              [style.background-color]="db.DICTIONARY.getData(integrationPresetProject.colorId, db.DICTIONARY.timeManagement.project.color)['hex']">
            </div>

            <!-- Descrição -->
            <div class="timer-table-cell description"
              [style.background-color]="db.DICTIONARY.getData(integrationPresetProject.colorId, db.DICTIONARY.timeManagement.project.color)['hex']">
              <input disabled class="input-description" [value]="integrationData.presetDescription">
            </div>

            <!-- day -->
            <div class="timer-table-cell day" *ngFor="let d of days; let days_index=index"
              [style.background-color]="db.DICTIONARY.getData(integrationPresetProject.colorId, db.DICTIONARY.timeManagement.project.color)['hex']"
              [ngClass]="{'today': isToday(d)}">
            </div>

            <!-- Options -->
            <div class="timer-table-cell options" *ngIf="edit"></div>
          </div>
          <!-- integration -->
        </cdk-virtual-scroll-viewport>
        <!-- timer-table-body -->

        <!-- timer-table-footer -->
        <div class="timer-table-footer">
          <!-- totals -->
          <div class="timer-table-footer-line totals">
            <div class="timer-table-footer-col number">
              <nb-icon title="Totais" icon="square-poll-vertical" status="basic" pack="fas"></nb-icon>
            </div>
            <div class="timer-table-footer-col project"></div>
            <div class="timer-table-footer-col task"></div>
            <div class="timer-table-footer-col tags"></div>
            <div class="timer-table-footer-col description"></div>
            <div class="timer-table-footer-col day" *ngFor="let d of days; let days_index=index"
              [ngClass]="{'zero': d.val == 0, 'today': isToday(d)}">
              <input disabled class="type_time input_time_total" [(ngModel)]="d.time" id="{{'input_time_'+days_index}}"
                id="input_time_total" name="input_time_total" name="{{'input_time_'+days_index}}" type="time"
                step="0" />
            </div>
            <div class="timer-table-footer-col options" *ngIf="edit"></div>
          </div>
          <!-- totals -->

          <!-- weekly-goal -->
          <div *ngIf="userConfig.weeklyGoal.active" class="timer-table-footer-line weekly-goal">
            <div class="timer-table-footer-col number">
              <nb-icon title="Meta Semanal" icon="flag" status="basic" pack="fas"></nb-icon>
            </div>
            <div class="timer-table-footer-col project"></div>
            <div class="timer-table-footer-col task"></div>
            <div class="timer-table-footer-col tags"></div>
            <div class="timer-table-footer-col description">
              <div *ngIf="mode == 'week'">
                <p *ngIf="weeklyGoal_status.status == 'not-set'">
                  N/A
                </p>

                <p *ngIf="weeklyGoal_status.status == 'up'" class="up">
                  <nb-icon icon="plus" status="primary" pack="fas"></nb-icon>
                  <span *ngIf="weeklyGoal_status.difH != 0">{{weeklyGoal_status.difH}}h</span>
                  <span *ngIf="weeklyGoal_status.difM != 0">{{weeklyGoal_status.difM}}m</span>
                </p>
                <p *ngIf="weeklyGoal_status.status == 'down'" class="down">
                  <nb-icon icon="down-long" status="danger" pack="fas"></nb-icon>
                  <span *ngIf="weeklyGoal_status.difH != 0">{{weeklyGoal_status.difH}}h</span>
                  <span *ngIf="weeklyGoal_status.difM != 0">{{weeklyGoal_status.difM}}m</span>
                </p>
                <p *ngIf="weeklyGoal_status.status == 'ok'" class="ok">
                  <nb-icon icon="flag-checkered" status="success" pack="fas"></nb-icon>
                </p>
              </div>
            </div>
            <div class="timer-table-footer-col day" *ngFor="let d of days; let days_index=index"
              [ngClass]="{'zero': weeklyGoal_days[days_index].status == 'not-set'}">
              <p *ngIf="weeklyGoal_days[days_index].status == 'not-set'">
                N/A
              </p>

              <p *ngIf="weeklyGoal_days[days_index].status == 'up'" class="up">
                <nb-icon icon="plus" status="primary" pack="fas"></nb-icon>
                <span *ngIf="weeklyGoal_days[days_index].difH != 0">{{weeklyGoal_days[days_index].difH}}h</span>
                <span *ngIf="weeklyGoal_days[days_index].difM != 0">{{weeklyGoal_days[days_index].difM}}m</span>
              </p>
              <p *ngIf="weeklyGoal_days[days_index].status == 'down'" class="down">
                <nb-icon icon="down-long" status="danger" pack="fas"></nb-icon>
                <span *ngIf="weeklyGoal_days[days_index].difH != 0">{{weeklyGoal_days[days_index].difH}}h</span>
                <span *ngIf="weeklyGoal_days[days_index].difM != 0">{{weeklyGoal_days[days_index].difM}}m</span>
              </p>
              <p *ngIf="weeklyGoal_days[days_index].status == 'ok'" class="ok">
                <nb-icon icon="flag-checkered" status="success" pack="fas"></nb-icon>
              </p>
            </div>
            <div class="timer-table-footer-col options" *ngIf="edit"></div>
          </div>
          <!-- weekly-goal -->

          <!-- add-line -->
          <div class="timer-table-footer-line add-line">
            <div class="timer-table-footer-col number">
              <button title="Nova Linha (Alt + Shift + N)" *ngIf="edit" nbButton appearance="ghost" status="primary"
                (click)="addLine()">
                <nb-icon icon="file-add-outline" pack="eva"></nb-icon>
              </button>
            </div>
            <div class="timer-table-footer-col project"></div>
            <div class="timer-table-footer-col task"></div>
            <div class="timer-table-footer-col tags"></div>
            <div class="timer-table-footer-col description"></div>
            <div class="timer-table-footer-col day" *ngFor="let d of days; let days_index=index"></div>
            <div class="timer-table-footer-col options" *ngIf="edit"></div>
          </div>
          <!-- add-line -->
        </div>
        <!-- timer-table-footer -->
      </div>
      <!-- timer-table -->

    </nb-card-body>
  </nb-card>
  <!-- table -->

  <!-- Assistente de Edição -->
  <nb-card [nbSpinner]="loader" *ngIf="edit" class="editor-wizard">
    <nb-card-header>
      <p>Assistente de Edição</p>
      <button (click)="showUserConfigModal()">
        <nb-icon icon="settings-outline" pack="eva"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-12 padding">
          <p *ngIf="!hasChanges">
            <nb-icon icon="save-outline" status="basic" pack="eva"></nb-icon>
            <nb-icon icon="checkmark-square-2" status="success" pack="eva"></nb-icon>
            Não existem alterações a serem salvas
          </p>
          <p *ngIf="hasChanges && errors.length == 0">
            <nb-icon icon="checkmark-square-2-outline" status="success" pack="eva"></nb-icon>
            Não existem erros de Preenchimento.
          </p>
          <div *ngIf="errors.length > 0">
            <p>
              <nb-icon icon="close-outline" status="danger" pack="eva"></nb-icon>
              Erros de Preenchimento:
            </p>
            <div *ngFor="let e of errors; let i=index">
              <p class="error"><b>{{i+1}})</b> {{e}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 padding" style="text-align: center">
          <div class="button-container">
            <button title="Salvar (Alt + Shift + S)" nbButton status="primary" size="small"
              [disabled]="!hasChanges ||  errors.length > 0" (click)="trySave()">
              <nb-icon icon="save-outline" pack="eva"></nb-icon>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
  <!-- Assistente de Edição -->

</div>