<div class="header-container">
  <div class="logo-container">
    <a (click)="menubuttonClick()" #menuERef href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline" pack="eva"></nb-icon>
    </a>

    <a class="logo" href="#/main/home">
      <img [src]="themeService.variables.logo" alt="logo_name" style="width: 100px;">
    </a>
  </div>

  <div class="header-element dev-badge" *ngIf="dev">
    <nb-icon icon="code" pack="eva"></nb-icon>
    <span>--dev</span>
  </div>

  <performance-measurement-display class="header-element"></performance-measurement-display>
</div>

<div class="header-container">
  <div class="header-controls">

    <theme-switcher class="header-element"></theme-switcher>
    <!-- <ngx-layout-direction-switcher class="direction-switcher"></ngx-layout-direction-switcher> -->

    <timer class="header-element"></timer>

    <div *ngIf="themeService.headerButtons.openTmTimerModal.show" class="control-item auto-hide">
      <button [disabled]="themeService.headerButtons.openTmTimerModal.disabled" title="Temporizador"
        (click)="_openTmTimerModal()">
        <nb-icon icon="clock-outline" pack="eva"></nb-icon>
      </button>
    </div>

    <div *ngIf="themeService.headerButtons.openNotif.show" class="control-item">
      <button [disabled]="themeService.headerButtons.openNotif.disabled" title="Notificações" (click)="_openNotif()">
        <div *ngIf="authService.numOfUnreadNotif > 0" class="notif-badge">
          <p>{{authService.numOfUnreadNotif}}</p>
        </div>
        <nb-icon disabled icon="bell-outline" pack="eva"></nb-icon>
      </button>
    </div>

    <div class="control-item">
      <nb-user [nbContextMenu]="userMenu" size="medium" [onlyPicture]="userPictureOnly"
        [name]="authService.localUser.fullName" [title]="authService.localUser.jobTitle"
        [picture]="authService.localUser.thumbnail">
      </nb-user>
    </div>
  </div>
</div>