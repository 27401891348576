<nb-card [nbSpinner]="loader">
  <nb-card-header>
    <nb-actions size="medium">
      <nb-action>
        Temporizador
      </nb-action>

      <nb-action style="width: 150px;">
        <label>Período</label>
        <select id="inpuy_mode" name="inpuy_mode" class="ic-form-control" [(ngModel)]="mode"
          (ngModelChange)="changeMode()">
          <option *ngFor="let m of modes" [ngValue]="m.id">{{m.title}}</option>
        </select>
      </nb-action>

      <nb-action [ngClass]="{'hide': mode!='week'}" style="width: 200px;">
        <label>Semana</label>
        <week-input #Week_input_REF [(week)]="dateWeek" [(month)]="dateMonth" [(week)]="dateWeek" [(year)]="dateYear"
          (weekChange)="changePeriod()">
        </week-input>
      </nb-action>

      <nb-action *ngIf="mode=='month'" style="width: 150px;">
        <label>Mês</label>
        <select id="input_month" name="input_month" class="ic-form-control" [(ngModel)]="dateMonth"
          (ngModelChange)="changePeriod()">
          <option *ngFor="let m of months" [ngValue]="m.id">{{m.name}}</option>
        </select>
      </nb-action>


      <nb-action style="width: 180px;">
        <label>De</label>
        <input [disabled]="mode!='manual'" class="ic-form-control" type="date" id="input_datePeriodFrom"
          name="input_datePeriodFrom" [(ngModel)]="datePeriodFrom_inputVal" (ngModelChange)="updateDatePeriodFrom()">
      </nb-action>
      <nb-action style="width: 180px;">
        <label>Até</label>
        <input [disabled]="mode!='manual'" class="ic-form-control" type="date" id="input_datePeriodTo"
          name="input_datePeriodTo" [(ngModel)]="datePeriodTo_inputVal" (ngModelChange)="updateDatePeriodTo()">
      </nb-action>

    </nb-actions>
  </nb-card-header>

  <nb-card-body>
    <div class="page-controls">
      <!-- view-mode-selector -->
      <div class="view-mode-selector">
        <div *ngFor="let viewMode of VIEW_MODES; trackBy: _trackByFn" class="view-mode"
          [ngClass]="{'selected': selectedViewMode == viewMode.id}">
          <div class="view-mode-btn" (click)="_selectViewMode(viewMode.id)">
            <svg-inline class="view-mode-icon" src="{{viewMode.icon}}"></svg-inline>
            <span class="view-mode-name">{{viewMode.name}}</span>
          </div>

          <div class="view-mode-selected-bar"></div>
        </div>
      </div>
      <!-- view-mode-selector -->
    </div>

    <!-- Lista -->
    <div [ngClass]="{'hide': selectedViewMode != 'list' }">
      <p *ngIf="errorMessage!=''" style="color: red;">{{errorMessage}}</p>

      <timer-list-tab #Timer_list_tab_REF [datePeriodFrom]="datePeriodFrom" [datePeriodTo]="datePeriodTo"
        [timeRecords]="localTimeRecords" (timeRecordsChange)="changeTimeRecords($event)" [edit]="canEdit" [mode]="mode">
      </timer-list-tab>
    </div>
    <!-- Lista -->

    <!-- Dashboard -->
    <div *ngIf="selectedViewMode == 'dashboard'">
      <timer-dashboard-tab [timeRecords]="localTimeRecords" [datePeriodFrom]="datePeriodFrom"
        [datePeriodTo]="datePeriodTo">
      </timer-dashboard-tab>
    </div>
    <!-- Dashboard -->
  </nb-card-body>
</nb-card>

<a href="#/main/time-management/timer-manager">Gerenciamento de Horas</a>