import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AuthService } from 'app/@core/services/auth.service';
import { UiFeedBackService } from 'app/@theme/services';
import { ThemeService } from 'app/@theme/theme.service';

const USER_CONFIG_PARAM_ID = "theme-switcher";

@Component({
    selector: 'theme-switcher',
    styleUrls: ['./theme-switcher.component.scss'],
    templateUrl: './theme-switcher.component.html',
})
export class ThemeSwitcherComponent implements OnInit, OnDestroy {
    className = "ThemeSwitcherComponent";
    private destroy$: Subject<void> = new Subject<void>();
    private keyCode = 'Digit9';
    private keyPressed = true;
    public show = false;
    public currentTheme = 'default';
    public themes = [
        {
            value: 'default',
            name: 'Light',
        },
        {
            value: 'dark',
            name: 'Dark',
        },
        {
            value: 'cosmic',
            name: 'Cosmic',
        },
        {
            value: 'corporate',
            name: 'Corporate',
        },
    ];
    constructor(
        public themeService: ThemeService,
        public authService: AuthService,
        public uiFeedBackService: UiFeedBackService,
    ) { }
    @HostListener('document:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.code == this.keyCode && event.altKey && event.shiftKey && !this.keyPressed) {
            this.keyPressed = true;
            this.toogleShow();
        }
    }
    @HostListener('document:keyup', ['$event'])
    onKeyUp(event: KeyboardEvent) {
        if (event.code == this.keyCode) {
            this.keyPressed = false;
        }
    }
    toogleShow() {
        this.uiFeedBackService.presentLoader()
            .then(() => {
                this.authService.set_parameter(USER_CONFIG_PARAM_ID, !this.show)
                    .then(() => {
                        this.uiFeedBackService.dismissLoader();
                    })
                    .catch((e) => {
                        this.uiFeedBackService.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
                    });
            });
    }
    shouldShowPerformance() {
        return this.show;
    }
    ngOnInit() {
        this.currentTheme = this.themeService.nbTheme.currentTheme;

        this.themeService.nbTheme.onThemeChange()
            .pipe(
                map(({ name }) => name),
                takeUntil(this.destroy$),
            )
            .subscribe((themeName) => this.currentTheme = themeName);

        this.authService.getUserParametersChanges()
            .pipe(
                takeUntil(this.destroy$),
            )
            .subscribe((userParameters) => {
                this.authService.get_parameter(USER_CONFIG_PARAM_ID)
                    .then((data) => {
                        const newShow = data ? data == true : false;
                        if (newShow != this.show)
                            this.show = newShow;
                    })
                    .catch((e) => {
                        this.uiFeedBackService.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
                    });

                this.authService.get_parameter('themeName')
                    .then((themeName) => {
                        this.themeService.changeTheme(themeName)
                        this.currentTheme = this.themeService.nbTheme.currentTheme;
                    })
                    .catch((e) => {
                        this.uiFeedBackService.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
                    });
            });
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
    changeTheme(themeName: string) {
        this.uiFeedBackService.presentLoader()
            .then(() => {
                this.themeService.changeTheme(themeName);
                this.authService.set_parameter('themeName', themeName)
                    .then(() => {
                        this.uiFeedBackService.dismissLoader();
                    })
                    .catch((e) => {
                        this.uiFeedBackService.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
                    })
            })
    }
}
