import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FirebaseModule } from 'app/@firebase';
import { ThemeModule } from 'app/@theme';

import { TimerListTabComponent } from './timer-list-tab.component';

const BASE_MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ThemeModule,
    FirebaseModule,
];

const COMPONENTS = [
    TimerListTabComponent,
];

@NgModule({
    imports: [...BASE_MODULES],
    exports: [...COMPONENTS],
    declarations: [...COMPONENTS],
})
export class TimerListTabModule { }