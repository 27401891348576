<div class="mode mode_assignees" cdkDropList [cdkDropListData]="cardAssignees"
    (cdkDropListDropped)="_assigneesOnDrop($event)">

    <div *ngIf="_loader" class="loader">
        <p>Carregando...</p>
    </div>

    <!-- cdkDrag -->
    <div *ngFor="let assignee of cardAssignees; trackBy: _trackByFn" [title]="assignee.fullName"
        class="pm-card-modal-assignee" cdkDrag
        [cdkDragDisabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeCardMembers && !pmCardModal.isUserAdmin)">
        <nb-user [name]="assignee.fullName" [title]="assignee.jobTitle" [picture]="assignee.thumbnail">
        </nb-user>

        <div class="ic-button-grp">
            <button
                [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeCardMembers && !pmCardModal.isUserAdmin)"
                nbButton status="danger" appearance="ghost" size="tiny" (click)="_remAssignee(assignee.uName)">
                <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
            </button>
        </div>
    </div>
    <!-- cdkDrag -->

    <!-- new assignee -->
    <div class="new-assignee">
        <span class="title">
            Adicionar
        </span>

        <div class="form">
            <ng-select id="newCardAssignee" name="newCardAssignee" required
                [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeCardMembers && !pmCardModal.isUserAdmin)"
                [(ngModel)]="newCardAssignee">
                <ng-option *ngFor="let boardMember of boardMembers4Sel" [value]="boardMember">
                    {{boardMember.fullName}}
                </ng-option>
            </ng-select>

            <button
                [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeCardMembers && !pmCardModal.isUserAdmin) || !newCardAssignee || newCardAssignee.id == ''"
                nbButton size="small" (click)="_addAssignee()">
                <nb-icon icon="person-add-outline" pack="eva"></nb-icon>
            </button>
        </div>
    </div>
    <!-- new assignee -->

    <p *ngIf="pmCardModal.board.onlyAdmChangeCardMembers && !pmCardModal.isUserAdmin" class="tiny-error">
        Somente admins podem alterar os membros do cartão
    </p>
</div>